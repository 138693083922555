import { message, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslate } from 'react-translate';
import { API_SERVEUR } from '../../../../helpers/const/API';
import handleServerResponse from '../../../../helpers/reponseManager/reponseHandler';
import getParameterForFetch from '../../../../helpers/function/paramFetch';
import './OpcTableComposite.scss';

export function bodyIsValid(body) {
  if (!body.firstApplicationDate) {
    message.error('Veuillez spécifier une date');
    return false;
  }
  if (!body.opc) {
    message.error('Veuillez spécifier un OPC');
    return false;
  }
  if (!body.indice) {
    message.error("Veuillez spécifier des indices pour l'indice composite");
    return false;
  }

  return true;
}
export const createIndiceCompositeToSave = (
  indiceCompositeToCreate,
  OpcID,
  firstApplicationDate,
) => {
  const indiceOfComposite = [];
  let body;
  const allWeight = indiceCompositeToCreate.map(indice => indice.weight);
  if (allWeight.reduce((a, b) => a + b, 0) !== 100) {
    message.error('Le poids total est différent de 100');
    return !body;
  }
  indiceCompositeToCreate.forEach(indice => {
    const elementBody = {
      indice: indice._id,
      weight: indice.weight,
    };
    indiceOfComposite.push(elementBody);
  });
  body = {
    firstApplicationDate,
    opc: OpcID,
    indice: indiceOfComposite,
  };
  return body;
};

//* ***************** ACTUAL COMPONENT****************//

function OpcTableComposite(props) {
  const t = useTranslate('translation');
  const {
    indicesComposite,
    history,
    setWeightForIndice,
    OpcID,
    firstApplicationDate,
    handleIndiceDelete,
    setForceUpdate,
    setIndiceComposite,
    settoggleCreationNewIndiceComposite,
  } = props;
  function saveIndiceComposite() {
    const url = `${API_SERVEUR}api/composite/saveIndiceComposite`;
    const body = createIndiceCompositeToSave(
      indicesComposite,
      history.location.state.opc._id,
      firstApplicationDate,
    );
    if (bodyIsValid) {
      const parameters = getParameterForFetch('POST', body);
      fetch(url, parameters)
        .then(res => res.json())
        .then(res => handleServerResponse(res))
        .then(() => {
          setIndiceComposite([]);
          setForceUpdate(true);
          settoggleCreationNewIndiceComposite(false);
        });
    }
  }
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const enabled = useCallback(() => {
    if (firstApplicationDate && OpcID && indicesComposite.length) {
      return setEnableSaveButton(false);
    }
    return setEnableSaveButton(true);
  }, [firstApplicationDate, OpcID, indicesComposite]);

  const [warningMessage, setwarningMessage] = useState('');
  const warningText = useCallback(() => {
    if (!firstApplicationDate) {
      return setwarningMessage('Veuillez selectionner une date');
    }
    if (!OpcID) {
      return setwarningMessage('Veuillez selectionner une date');
    }
    if (!indicesComposite) {
      return setwarningMessage('Veuillez selectionner une date');
    }
    if (indicesComposite.length < 1) {
      return setwarningMessage("Un indice composite doit être composé d'au moins deux indices");
    }
    return setwarningMessage('');
  }, [OpcID, firstApplicationDate, indicesComposite]);

  useEffect(() => {
    enabled();
    warningText();
  }, [firstApplicationDate, OpcID, indicesComposite, enabled, warningText]);

  return (
    <div className="tableCompositeContainer">
      <table>
        <thead>
          <tr>
            <th>{t('CODE_ISIN')}</th>
            <th className="large-size">{t('LABEL')}</th>
            <th>{t('SOURCE')}</th>
            <th>{t('COTATION_LOCATION')}</th>
            <th className="small-size">{t('DEVISE')}</th>
            <th className="medium-size">{t('WEIGHT')}</th>
            <th className="small-size">{t('DELETE')}</th>
          </tr>
        </thead>
        <tbody>
          {indicesComposite.map(indice => (
            <tr key={indice._id}>
              <td>{indice.codeIsin}</td>
              <td>{indice.label}</td>
              <td>{indice.source}</td>
              <td>{indice.cotationLocation}</td>
              <td>{indice.devise}</td>
              <td>
                <input
                  className="tableCompositeInput"
                  type="number"
                  min="1"
                  max="99"
                  onChange={e => setWeightForIndice(e, indice)}
                />
              </td>
              <td>
                <button
                  className="roundButton roundSecondary"
                  type="button"
                  onClick={() => {
                    handleIndiceDelete(indice);
                  }}
                >
                  <i className="icon-bin" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="buttonContainer text-right">
        <Tooltip title={warningMessage}>
          <button
            type="button"
            className="button secondary"
            onClick={saveIndiceComposite}
            disabled={enableSaveButton}
          >
            {t('SAVE')}
          </button>
        </Tooltip>
      </div>
    </div>
  );
}

export default withRouter(OpcTableComposite);
