import HomePage from '../../Page/HomePage/HomePage';
import OpcPage from '../../Page/OPCPage/Opc';
import ReferentielPage from '../../Page/ReferentielPage/ReferentielPage';
import ConversionPage from '../../Page/ConversionPage/ConversionPage';
import ImportPage from '../../Page/ImportPage/ImportPage';

const routes = [
  {
    id: 0,
    value: 'Tableau de bord',
    path: '/accueil',
    icon: 'icon-display',
    component: HomePage,
  },
  {
    id: 1,
    value: 'Les OPC',
    path: '/opc',
    icon: 'icon-home',
    component: OpcPage,
  },
  {
    id: 2,
    value: 'Référentiel des indices',
    path: '/referentiel',
    icon: 'icon-list2',
    component: ReferentielPage,
  },
  {
    id: 3,
    value: 'Taux de conversions',
    path: '/conversion',
    icon: 'icon-coin-euro',
    component: ConversionPage,
  },
  {
    id: 4,
    value: 'Import des données',
    path: '/import',
    icon: 'icon-upload3',
    component: ImportPage,
  },
];

export default routes;
