/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Checkbox, Icon, Input } from 'antd';
import { useTranslate } from 'react-translate';
import './OpcModalIndice.scss';

function OpcModalIndice(props) {
  const { isModalIndiceOpen, setModalIndiceOpen, indicesForComposites, setIndiceComposite } = props;
  const t = useTranslate('translation');
  const [selectedIndices, setSelectedIndices] = useState([]);
  function selectIndice(indice) {
    const index = selectedIndices.findIndex(selectedIndice => selectedIndice._id === indice._id);
    if (index > -1) {
      selectedIndices.splice(index, 1);
    } else {
      setSelectedIndices([...selectedIndices, indice]);
    }
  }

  function retrieveValuesForIndiceComposite() {
    setIndiceComposite(selectedIndices);
    setModalIndiceOpen(!isModalIndiceOpen);
  }

  const [searchIndice, setSearchIndice] = useState('');
  const [copyIndice, setCopyIndice] = useState([]);

  const filterBy = useCallback(searchIndice => {
    const re = new RegExp(escapeRegExp(searchIndice), 'i');
    return person => {
      for (const prop in person) {
        if (!Object.keys(person).includes(prop)) {
          return false;
        }
        if (re.test(person[prop])) {
          return true;
        }
      }
      return false;
    };
  }, []);

  const filterIndices = useCallback(() => {
    const found = indicesForComposites.filter(filterBy(searchIndice));
    setCopyIndice(found);
  }, [filterBy, indicesForComposites, searchIndice]);

  const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

  useEffect(() => {
    filterIndices();
  }, [filterIndices, searchIndice]);
  useEffect(() => {
    setCopyIndice(indicesForComposites);
  }, [indicesForComposites]);

  return (
    <Modal
      title={t('OPC_MODAL_TITLE')}
      visible={isModalIndiceOpen}
      onCancel={() => setModalIndiceOpen(!isModalIndiceOpen)}
      onOk={retrieveValuesForIndiceComposite}
    >
      <div className="inputContainer text-right">
        <Input
          value={searchIndice}
          onChange={e => setSearchIndice(e.target.value)}
          className="inputSearch"
          placeholder={t('SEARCH_INDICE')}
          prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
        />
      </div>

      <table className="margin-top-16">
        <thead>
          <tr>
            <th className="small-size" />
            <th>{t('CODE_ISIN')}</th>
            <th className="large-size">{t('LABEL')}</th>
            <th>{t('SOURCE')}</th>
            <th>{t('COTATION_LOCATION')}</th>
            <th className="small-size">{t('DEVISE')}</th>
          </tr>
        </thead>
        <tbody>
          {copyIndice.map(indice => (
            <tr key={indice._id}>
              <td>
                <Checkbox onChange={() => selectIndice(indice)} />
              </td>
              <td>{indice.codeIsin}</td>
              <td>{indice.label}</td>
              <td>{indice.source}</td>
              <td>{indice.cotationLocation}</td>
              <td>{indice.devise}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
}
export default OpcModalIndice;
