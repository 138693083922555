import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-translate';
import Months from '../../helpers/const/Months';
import { Link } from 'react-router-dom';


const GeneralOpcTableRow = props => {
  const { opc } = props;
  const t = useTranslate('translation');

  GeneralOpcTableRow.propTypes = {
    opc: PropTypes.shape({
      codeIsi: PropTypes.string,
      label: PropTypes.string,
      OPCV: PropTypes.string,
      nature: PropTypes.string,
      devise: PropTypes.string,
      typeDeCour: PropTypes.string,
      cloture: PropTypes.number,
      actif: PropTypes.bool,
    }),
  };

  GeneralOpcTableRow.defaultProps = {
    opc: PropTypes.shape({
      codeIsi: 'FR038390',
      label: 'Allianz',
      OPCV: 'L2',
      nature: 'Classique',
      devise: 'EUR',
      typeDeCour: 'Dernier',
      cloture: 6,
      actif: 'Oui',
    }),
  };

  const getMatchingMonthOfClosing = monthNumber => {
    if (!monthNumber) {
      return '';
    }
    const month = Months.find(Month => Month.value === monthNumber);

    return month.name;
  };

  return (
    <tr key={opc._id}>
      <td>{opc.codeIsin}</td>
      <td>{opc.label}</td>
      <td>{opc.valorisation}</td>
      <td>{opc.nature}</td>
      <td>{opc.devise}</td>
      <td>{opc.typeOfStockMarket ? t(opc.typeOfStockMarket.toUpperCase()) : '-'}</td>
      <td>{getMatchingMonthOfClosing(opc.closingMonth)}</td>
      <td>
        {opc.actif ? (
          <i className="icon-checkmark tableState success"/>
        ) : (
          <i className="icon-cross tableState error"/>
        )}
      </td>
      <td>
        <Link
          to={{
            pathname: '/gestionOpc',
            state: { opc, isNew: false },
            search: `?codeIsin=${opc.codeIsin}`,
          }}
        >
          <button className="roundButton roundSecondary" type="button" placeholder="Voir">
            <i className="icon-eye"/>
          </button>
        </Link>
      </td>
    </tr>
  );
};

export default GeneralOpcTableRow;
