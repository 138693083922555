/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react';
import { Input, Icon, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-translate';
import OpcBody from './OpcBody/OpcBody';
import './Opc.scss';
import getParameterForFetch from '../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../helpers/const/API';

function OpcPage() {
  const t = useTranslate('translation');
  const [Opc, setOpc] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getOpc = useCallback(() => {
    const body = {
      search: inputValue,
    };
    const parameters = getParameterForFetch('POST', body);
    const url = `${API_SERVEUR}api/opc/retrieveAllOpc`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setOpc(res));
  }, [inputValue]);

  useEffect(() => {
    getOpc();
  }, [getOpc, inputValue]);

  const handleChangeInput = e => {
    const valueInput = e.target.value;
    setInputValue(valueInput);
  };
  return (
    <div className="opc">
      <div className="flex conversion-page-header">
        <h1 className="title">{t('TITLE_OPC')}</h1>

        <div className="inputContainer text-right">
          <Input
            value={inputValue}
            onChange={e => handleChangeInput(e)}
            className="inputSearch"
            placeholder={t('SEARCH_OPC')}
            prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </div>
      </div>
      <div className="blockContainer medium">
        <div className="buttonContainer text-right margin-bottom-1">
          <Link
            to={{
              pathname: '/gestionOpc',
              state: { isNew: true, opc: {} },
              search: `?codeIsin`,
            }}
          >
            <Button className="primary button ripple" type="primary" icon="plus" size="large">
              {t('ADD_OPC')}
            </Button>
          </Link>
        </div>
        <OpcBody opcs={Opc} history={history} />
      </div>
    </div>
  );
}

export default OpcPage;
