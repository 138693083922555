const ValorisationDate = 'Date de valorisation';

const defaultLiquidativeValue = [
  {
    name: 'Code ISIN',
    hiddenName: 'codeIsin',
    column: 1,
    id: 0,
  },
  {
    name: 'Valeur Liquidative',
    hiddenName: 'liquidatingValue',
    column: 2,
    id: 1,
  },
  {
    name: ValorisationDate,
    hiddenName: 'valorisationDate',
    column: 3,
    id: 2,
  },
  {
    name: 'Coupon versé',
    hiddenName: 'couponPaid',
    column: 4,
    id: 3,
  },
];

export const constForOnglet = onglet => {
  switch (onglet) {
    case 'INDICE':
      return {
        values: [
          {
            name: 'Code ISIN',
            hiddenName: 'codeIsin',
            column: 1,
            id: 0,
          },
          {
            name: 'Place de cotation (code)',
            hiddenName: 'cotationLocation',
            column: 2,
            id: 1,
          },
          {
            name: 'Devise',
            hiddenName: 'devise',
            column: 3,
            id: 2,
          },
          {
            name: ValorisationDate,
            hiddenName: 'valorisationDate',
            column: 4,
            id: 3,
          },
          {
            name: 'Premier cours',
            hiddenName: 'firstStockMarket',
            column: 5,
            id: 4,
          },
          {
            name: 'Cours le plus bas',
            hiddenName: 'lowestStockMarket',
            column: 6,
            id: 5,
          },
          {
            name: 'Cours le plus haut',
            hiddenName: 'highestStockMarket',
            column: 7,
            id: 6,
          },
          {
            name: 'Dernier cours',
            hiddenName: 'lastStockMarket',
            column: 8,
            id: 7,
          },
          {
            name: "Cours d'estimation",
            hiddenName: 'estimationStockMarket',
            column: 9,
            id: 8,
          },
          {
            name: 'Libellé',
            hiddenName: 'label',
            column: 10,
            id: 9,
          },
        ],
        path: 'api/valorisationIndice/saveValorisationIndice',
      };
    case 'CONVERSION':
      return {
        values: [
          {
            name: 'Code ISIN',
            hiddenName: 'codeIsin',
            column: 1,
            id: 0,
          },
          {
            name: 'Place de cotation (code)',
            hiddenName: 'cotationLocation',
            column: 2,
            id: 1,
          },
          {
            name: 'Devise',
            hiddenName: 'devise',
            column: 3,
            id: 2,
          },
          {
            name: ValorisationDate,
            hiddenName: 'valorisationDate',
            column: 4,
            id: 3,
          },
          {
            name: 'Premier cours',
            hiddenName: 'firstStockMarket',
            column: 5,
            id: 4,
          },
          {
            name: 'Cours le plus bas',
            hiddenName: 'lowestStockMarket',
            column: 6,
            id: 5,
          },
          {
            name: 'Cours le plus haut',
            hiddenName: 'highestStockMarket',
            column: 7,
            id: 6,
          },
          {
            name: 'Dernier cours',
            hiddenName: 'lastStockMarket',
            column: 8,
            id: 7,
          },
          {
            name: "Cours d'estimation",
            hiddenName: 'estimationStockMarket',
            column: 9,
            id: 8,
          },
          {
            name: 'Libellé',
            hiddenName: 'label',
            column: 10,
            id: 9,
          },
        ],
        path: 'api/valorisationConversionRate/saveNewValorisationConversionRate',
      };
    case 'LIQUIDATIVE':
      return {
        values: defaultLiquidativeValue,
        path: 'api/liquidationValue/saveNewLiquidationsValues',
      };
    case 'SOR':
      return {
        values: [
          {
            name: 'Code ISIN',
            hiddenName: 'codeIsin',
            column: 1,
            id: 0,
          },
          {
            name: 'Place de cotation (code)',
            hiddenName: 'cotationLocation',
            column: 2,
            id: 1,
          },
          {
            name: 'Devise',
            hiddenName: 'devise',
            column: 3,
            id: 2,
          },
          {
            name: ValorisationDate,
            hiddenName: 'valorisationDate',
            column: 4,
            id: 3,
          },
          {
            name: 'Premier cours',
            hiddenName: 'firstStockMarket',
            column: 5,
            id: 4,
          },
          {
            name: 'Cours le plus bas',
            hiddenName: 'lowestStockMarket',
            column: 6,
            id: 5,
          },
          {
            name: 'Cours le plus haut',
            hiddenName: 'highestStockMarket',
            column: 7,
            id: 6,
          },
          {
            name: 'Dernier cours',
            hiddenName: 'lastStockMarket',
            column: 8,
            id: 7,
          },
          {
            name: "Cours d'estimation",
            hiddenName: 'estimationStockMarket',
            column: 9,
            id: 8,
          },
          {
            name: 'Libellé',
            hiddenName: 'label',
            column: 10,
            id: 9,
          },
          {
            name: 'Coupon couru',
            hiddenName: 'coupon',
            column: 11,
            id: 10,
          },
        ],
        path: 'api/valorisationIndice/importSORData',
      };
    default:
      return {
        values: defaultLiquidativeValue,
      };
  }
};

export const switchButton = [
  { value: 'INDICE', text: "Import des valeurs d'indices" },
  { value: 'LIQUIDATIVE', text: 'Import des valeurs liquidatives' },
  { value: 'CONVERSION', text: 'Import des taux de conversion' },
  { value: 'SOR', text: 'Import des fichiers .SOR'}
];
