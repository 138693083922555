import React from 'react';
import { Checkbox } from 'antd';
import './SelectColumnImport.scss';
import { useTranslate } from 'react-translate';

const SelectColumImport = props => {
  const t = useTranslate('translation');
  const { handleColumnChange, name, id, max, couponColumnIsDisabled, defaultValue } = props;
  return (
    <div className="row selectImportContainer-content">
      <div className="columns small-5">
        <p className="margin-right-1">{name}</p>
      </div>

      <div className="columns small-5">
        <input
          type="number"
          min="1"
          required
          max={max}
          label={name}
          id={id}
          defaultValue={defaultValue}
          onChange={handleColumnChange}
        />
      </div>

      <div className="columns auto">
        {name === 'Coupon versé' ? (
          <Checkbox defaultChecked onChange={e => couponColumnIsDisabled(e)}>
            {t('DISABLED')}
          </Checkbox>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default SelectColumImport;
