/* eslint-disable no-restricted-globals */
import React, { useState, Fragment } from 'react';
import { useTranslate } from 'react-translate';
import { Link } from 'react-router-dom';
import Months from '../../../helpers/const/Months';

function OpcBody(props) {
  const t = useTranslate('translation');
  const { opcs } = props;
  const getMatchingMonthOfClosing = monthNumber => {
    if (!monthNumber) {
      return '';
    }
    const month = Months.find(Month => Month.value === monthNumber);

    return month.name;
  };
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(50);
  const moreOpc = () => {
    if (max + 50 > opcs.length + 50) {
      return;
    }
    setMin(min + 50);
    setMax(max + 50);
  };
  const lessOpc = () => {
    if (min - 50 < 0) {
      return;
    }
    setMin(min - 50);
    setMax(max - 50);
  };
  return (
    <div className="tableContainer medium">
      <Fragment>
        <div className="paginationContainer">
          <button
            type="button"
            className="ant-btn secondary button ripple"
            onClick={() => lessOpc()}
          >
            {' '}
            -{' '}
          </button>
          <div className="paginationContainer-text">
            {min} - {max}
          </div>
          <button
            type="button"
            className="ant-btn secondary button ripple"
            onClick={() => moreOpc()}
          >
            +
          </button>
        </div>
      </Fragment>
      <table>
        <thead>
          <tr>
            <td className="medium-size">{t('CODE_ISIN')}</td>
            <td className="large-size">{t('LABEL')}</td>
            <td>{t('OPCVM')}</td>
            <td>{t('NATURE')}</td>
            <td className="small-size">{t('DEVISE')}</td>
            <td>{t('STOCK_MARKET_TYPE')}</td>
            <td>{t('CLOTURE_DATE')}</td>
            <td>{t('ACTIF')}</td>
            <td className="small-size">{t('VOIR')}</td>
          </tr>
        </thead>
        <tbody>
          {opcs.slice(min, max).map(opc => (
            <tr key={opc._id}>
              <td>{opc.codeIsin}</td>
              <td>{opc.label}</td>
              <td>{opc.valorisation}</td>
              <td>{opc.nature}</td>
              <td>{opc.devise}</td>
              <td>{opc.typeOfStockMarket ? t(opc.typeOfStockMarket.toUpperCase()) : '-'}</td>
              <td>{getMatchingMonthOfClosing(opc.closingMonth)}</td>
              <td>
                {opc.actif ? (
                  <i className="icon-checkmark tableState success" />
                ) : (
                  <i className="icon-cross tableState error" />
                )}
              </td>
              <td>
                <Link
                  to={{
                    pathname: '/gestionOpc',
                    state: { opc, isNew: false },
                    search: `?codeIsin=${opc.codeIsin}`,
                  }}
                >
                  <button className="roundButton roundSecondary" type="button" placeholder="Voir">
                    <i className="icon-eye" />
                  </button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
OpcBody.proptype = {};

export default OpcBody;
