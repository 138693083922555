/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { useTranslate } from 'react-translate';

import { API_SERVEUR } from '../../helpers/const/API';
import RegisterUserHook from '../../hooks/RegisterUserHook';
import getParameterForFetch from '../../helpers/function/paramFetch';
import handleServerResponse from '../../helpers/reponseManager/reponseHandler';
import './UserManager.scss';

function UserManager(props) {
  const { history } = props;
  const { inputs, handleInputChange } = RegisterUserHook();
  const t = useTranslate('translation');
  const [userIsAdmin, setUserIsAdmin] = useState(false);

  const signup = async () => {
    Object.assign(inputs, { isAdmin: userIsAdmin });
    const parameters = getParameterForFetch('POST', inputs);

    try {
      const urlRegister = 'api/users/register';
      const url = `${API_SERVEUR}${urlRegister}`;
      fetch(url, parameters)
        .then(res => res.json())
        .then(res => handleServerResponse(res))
        .then(() => {
          history.push('/admin/gestion');
        });
    } catch (error) {
      throw new Error(error);
    }
  };
  const { handleSubmit } = RegisterUserHook(signup);
  return (
    <div className="block-main-container">
      <div className="blockContainer">
        <h4 className="subtitle">{t('USER_CREATION_TITLE')}</h4>
        <div className="flex">
          <form onSubmit={handleSubmit} className="max-width">
            <div>
              <label>{t('NAME')}</label>
              <input
                placeholder={t('NAME')}
                name="name"
                label="name"
                onChange={handleInputChange}
                value={inputs.firstName}
                required
                type="text"
              />
            </div>
            <div>
              <label>{t('MAIL')}</label>
              <input
                placeholder={t('MAIL')}
                name="email"
                label="email"
                className="max-width"
                onChange={handleInputChange}
                value={inputs.email}
                required
                type="mail"
              />
            </div>
            <div>
              <label>{t('PASSWORD')}</label>
              <input
                placeholder={t('PASSWORD')}
                name="password"
                label="password"
                onChange={handleInputChange}
                value={inputs.password}
                required
                type="password"
              />
            </div>
            <div>
              <label>{t('CONFIRM_PASSWORD')}</label>
              <input
                placeholder={t('CONFIRM_PASSWORD')}
                name="password2"
                label="password2"
                onChange={handleInputChange}
                value={inputs.password2}
                required
                type="password"
              />
            </div>
            <div>
              <Checkbox onChange={() => setUserIsAdmin(!userIsAdmin)}>
                {t('USER_IS_ADMIN')}
              </Checkbox>
            </div>

            <div className="text-right buttonContainer max-width">
              <button type="submit" className="ant-btn button secondary ant-btn-submit">
                {t('SAVE')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserManager;
