/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { message, Checkbox } from 'antd';
import Papa from 'papaparse';
import _ from 'lodash';
import moment from 'moment';

import { constForOnglet, switchButton } from '../../helpers/const/importArray';

import UploadFile from './ImportFileManager/FileUploader/UploadFile';
import SelectColumImport from './ImportFileManager/SelectColumnImported/SelectColumImport';
import SimpleModal from '../../component/shared/modal/ModalButtonCall';
import FileImporter from './ImportFileManager/FileImporter/FileImporter';
import getParameterForFetch from '../../helpers/function/paramFetch';
import './ImportPage.scss';
import { API_SERVEUR } from '../../helpers/const/API';
import handleServerResponse from '../../helpers/reponseManager/reponseHandler';

const DEFAULT_TYPE_SELECTED = 'INDICE';
const fakeEvent = {
  stopPropagation: () => null,
};
let isOnFileDeleteCallbackDisabled = false;

class ImportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      header: [],
      filesPreview: [],
      dataTypeSelected: DEFAULT_TYPE_SELECTED,
      couponColumnDisabled: true,
      headerIgnored: true,
    };

    this.getConstanteForImport = this.getConstanteForImport.bind(this);
    this.handleColumnChange = this.handleColumnChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFileDelete = this.onFileDelete.bind(this);
    this.setDateFormat = this.setDateFormat.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.switchHeaderIgnored = this.switchHeaderIgnored.bind(this);
    this.removeAllFilesFromDropzoneArea = this.removeAllFilesFromDropzoneArea.bind(this);
    this.removeLastFileInsertedFromDropzoneArea = this.removeLastFileInsertedFromDropzoneArea.bind(
      this,
    );
    this.handleParsedFile = this.handleParsedFile.bind(this);
    this.toggleOptionsAfterCSVParse = this.toggleOptionsAfterCSVParse.bind(this);
    this.handleSubmitedCSVFiles = this.handleSubmitedCSVFiles.bind(this);
    this.couponColumnIsDisabled = this.couponColumnIsDisabled.bind(this);
    this.disableOnFileDeleteCallback = this.disableOnFileDeleteCallback.bind(this);
    this.enableOnFileDeleteCallback = this.enableOnFileDeleteCallback.bind(this);
  }


  componentWillMount() {
    this.setState({
      typeOfData: 'valeurs d\'indices',
      ongletSwitchButton: switchButton,
      valuesOfTypeOfData: constForOnglet('INDICE'),
      dateFormat: 'DD/MM/YYYY',
    });
  }

  shouldComponentUpdate(nextState) {
    const { valuesOfTypeOfData } = this.state;
    return valuesOfTypeOfData !== nextState.valuesOfTypeOfData;
  }

  getConstanteForImport(e) {
    const ongletImportPage = e.target.value;
    let typeOfDataUsed;
    if (e.target.value === 'LIQUIDATIVE') {
      typeOfDataUsed = 'valeurs liquidatives';
    }
    if (e.target.value === 'CONVERSION') {
      typeOfDataUsed = 'taux de conversions';
    }
    if (e.target.value === 'INDICE') {
      typeOfDataUsed = 'valeurs d\'indices';
    }
    if (e.target.value === 'SOR') {
      typeOfDataUsed = 'fichiers SOR';
    }
    this.setState(
      {
        typeOfData: typeOfDataUsed,
        valuesOfTypeOfData: constForOnglet(ongletImportPage),
        dataTypeSelected: ongletImportPage,
      },
      () => {
        const { valuesOfTypeOfData } = this.state;
        this.shouldComponentUpdate(valuesOfTypeOfData);
      },
    );
  }

  switchHeaderIgnored(e) {
    this.setState({ headerIgnored: e.target.checked })
  };

  setDateFormat(e) {
    this.setState({ dateFormat: e.target.value });
  }

  handleChange = filesUploaded => {
    if (isOnFileDeleteCallbackDisabled) {
      // This option is used in case of remove all files action (the state is fully empty manually)
      return;
    }
    if (!filesUploaded.length) {
      this.emptyState();
    } else {
      const lastFileIndex = filesUploaded.length - 1;
      this.handleFile(filesUploaded[lastFileIndex]);
    }
  };

  emptyState() {
    this.setState({
      header: [],
      files: [],
      filesPreview: [],
    });
  }

  onFileDelete(fileToRemove) {
    const { files, filesPreview } = this.state;

    if (isOnFileDeleteCallbackDisabled) {
      // This option is used in case of remove all files action (the state is fully empty manually)
      return;
    }

    this.disableOnFileDeleteCallback();

    setTimeout(() => {
      this.enableOnFileDeleteCallback();
    }, 2000);

    const newFiles = files.filter(f => f.name !== fileToRemove.name);
    const newFilesToPreview = filesPreview.filter(f => f.name !== fileToRemove.name);
    this.setState(
      {
        files: newFiles,
        filesPreview: newFilesToPreview,
      },
      () => {
        message.success('Le fichier a bien été supprimé');
      },
    );
  }

  handleFile(file) {
    if (this.state.dataTypeSelected === 'SOR' && this.getFileExt(file.name) !== 'SOR') {
      this.removeLastFileInsertedFromDropzoneArea();
      message.error(
        'Le fichier que vous essayer d\'importer n\'est pas un fichier SOR',
      );
      return;
    }

    if (this.state.dataTypeSelected === 'SOR') {
      this.parseSORFile(file, fileParseResponse => this.handleParsedSORFile(fileParseResponse, file.name));
    } else {
      this.parseFile(file, fileParsedResponse => this.handleParsedFile(fileParsedResponse.data, file.name));
    }
  }

  getFileExt(filename) {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  }


  parseFile(file, callback) {
    Papa.parse(file, {
      skipEmptyLines: 'greedy',
      complete: callback,
    });
  }

  parseSORFile(file, callback) {
    Papa.parse(file, {
      delimiter: ',',
      skipEmptyLines: 'greedy',
      complete: callback,
    });
  }


  handleParsedSORFile(parsingResponse, filename) {
    if (parsingResponse.errors.length > 0) {
      this.removeLastFileInsertedFromDropzoneArea();
      message.error(
        'Une erreur s\'est produite lors de l\'interprétation du fichier.',
      );
      return;
    }
    const data = parsingResponse.data
      .filter(row => row.length === 22 && row[0] === '0001')
      .map(row => [
        row[1].substring(2, 26).trim(),
        row[1].substring(26, 29),
        row[7],
        row[9],
        row[10],
        row[12],
        row[11],
        row[13],
        row[14],
        row[2].trim(),
        row[19],
      ]);
    this.saveFileInState(data, filename);
  }

  handleParsedFile(file, fileName) {
    const { headerIgnored, header } = this.state;
    if (!headerIgnored) {
      const fileHeader = file[0];
      if (header.length) {
        const areHeadersEquals = this.compareHeaders(fileHeader);
        if (!areHeadersEquals) {
          // remove file
          this.removeLastFileInsertedFromDropzoneArea();
          message.error(
            'Les fichiers que vous essayer d\'importer n\'ont pas les mêmes entêtes, le dernier fichier n\'a pas été pris en compte',
          );
          return;
        }
      } else {
        this.setState({
          header: fileHeader,
        });
      }
    }
    if (this.areFilesStructuresDifferent(file)) {
      this.removeLastFileInsertedFromDropzoneArea();
      message.error(
        'Les fichiers que vous essayer d\'importer n\'ont pas le même nombre de colonnes, le dernier fichier n\'a pas été pris en compte',
      );
      return;
    }
    this.saveFileInState(file, fileName);
  }

  removeLastFileInsertedFromDropzoneArea() {
    const { files } = this.state;
    this.fileImporterRef.dropzoneRef.handleRemove(files.length)(fakeEvent);
  }

  areFilesStructuresDifferent(newFile) {
    const { files } = this.state;

    return files.length && files[0].data[0].length !== newFile[0].length;
  }


  saveFileInState(fileToSave, fileName) {
    const { files, filesPreview } = this.state;
    this.setState({
      files: files.concat({
        name: fileName,
        data: fileToSave,
      }),
      filesPreview: filesPreview.concat({
        name: fileName,
        data: fileToSave.slice(0, 4),
      }),
    });
  }

  compareHeaders(newFileHeader) {
    const { header } = this.state;
    if (_.isEqual(header, newFileHeader)) {
      return true;
    }
    return false;
  }

  static transformValueToNumber(fileItem) {
    if (!fileItem) {
      return undefined;
    }
    const regex = new RegExp('%', 'g', 'u');
    const itemNumber = fileItem.replace(regex, '');
    const isNumberOrNot = parseFloat(itemNumber);
    if (!isNaN(itemNumber)) {
      return isNumberOrNot;
    }
    return itemNumber;
  }

  couponColumnIsDisabled(e) {
    this.setState({
      couponColumnDisabled: e.target.checked,
    });
  }

  handleColumnChange(e) {
    const { valuesOfTypeOfData } = this.state;
    const newValuesOfTypeOfData = valuesOfTypeOfData;
    const index = parseInt(e.target.value, 10);
    newValuesOfTypeOfData.values[e.target.id].column = index;
    this.setState({
      valuesOfTypeOfData: newValuesOfTypeOfData,
    });
  }

  handleSubmitedCSVFiles(e) {
    e.preventDefault();

    const { valuesOfTypeOfData, files, headerIgnored } = this.state;

    const createdItemsArray = [];
    files.forEach(file => {
      file.data
        .filter((row, index) => index !== 0 || !headerIgnored)
        .forEach(row => {
          createdItemsArray.push(this.createObjectFromRow(row, valuesOfTypeOfData));
        });
    });

    this.saveCreatedValues(createdItemsArray);
  }

  saveCreatedValues(createdItemsArray) {
    const {
      valuesOfTypeOfData: { path },
    } = this.state;
    const { history } = this.props;
    const parameters = getParameterForFetch('POST', createdItemsArray);
    const url = `${API_SERVEUR}${path}`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => {
        if (!res.success) {
          throw res.code === 'partial.already.known' ? res.message : `Le problème suivant est survenu:  ${res.message}`;
        }
        handleServerResponse(res);
        this.resetForm();
        history.push('/import');
      })
      .catch(error => {
        message.error(error);
      });
  }

  resetForm() {
    this.removeAllFilesFromDropzoneArea();
    this.emptyState();
  }

  removeAllFilesFromDropzoneArea() {
    const { files } = this.state;
    this.disableOnFileDeleteCallback();

    for (let i = 0, iMax = files.length; i < iMax; i++) {
      this.fileImporterRef.dropzoneRef.handleRemove(0)(fakeEvent);
    }

    setTimeout(() => {
      this.enableOnFileDeleteCallback();
    }, 2000);
  }

  disableOnFileDeleteCallback() {
    isOnFileDeleteCallbackDisabled = true;
  }

  enableOnFileDeleteCallback() {
    isOnFileDeleteCallbackDisabled = false;
  }

  createObjectFromRow(row, valuesOfTypeOfData) {
    const { couponColumnDisabled } = this.state;
    const object = {};
    valuesOfTypeOfData.values.forEach(value => {
      if (couponColumnDisabled && value.hiddenName === 'couponPaid') {
        return;
      }
      if (value < 1 || value >= row.length) {
        return;
      }
      let valueToSave = row[value.column - 1];
      if (value.hiddenName === 'valorisationDate') {
        const dateFormat = this.state.dataTypeSelected === 'SOR' ? 'YYYYMMDD' : this.state.dateFormat;
        valueToSave = moment(valueToSave, dateFormat);
      } else {
        valueToSave = ImportPage.transformValueToNumber(valueToSave);
      }
      object[value.hiddenName] = valueToSave;
    });
    return object;
  }


  toggleOptionsAfterCSVParse() {
    const {
      files,
      valuesOfTypeOfData,
      filesPreview,
      headerIgnored,
      typeOfData,
      couponColumnDisabled,
    } = this.state;
    if (files.length) {
      const maxValueOfInput = Object.keys(files[0].data[0]).length;
      return this.state.dataTypeSelected === 'SOR' ? (
        <div className="selectImportContainer">
          <div className="form-container">
            <form className="import-buttons-container" onSubmit={this.handleSubmitedCSVFiles}>
              <UploadFile
                typeOfData={typeOfData}
                file={files}
                valuesOfTypeOfData={valuesOfTypeOfData}
                submit={this.handleSubmitedCSVFiles}
              />
              <SimpleModal
                files={filesPreview}
                valuesHeader={valuesOfTypeOfData}
                headerIgnored={headerIgnored}
                couponColumnDisabled={couponColumnDisabled}
              />
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className="selectImportContainer">
            <h5 className="switchContainer-content-subtitle">Sélection du format des dates</h5>
            <div className="form-container">
              <label>Format des dates</label>
              <select onChange={this.setDateFormat} name="dateFormat">
                <option value="DD/MM/YYYY">jj/mm/aaaa</option>
                <option value="DD/MM/YY">jj/mm/aa</option>
                <option value="MM/DD/YYYY">mm/jj/aaaa</option>
                <option value="YYYYMMDD">aaaammjj</option>
              </select>
            </div>

          </div>
          <div className="selectImportContainer">
            <h5 className="switchContainer-content-subtitle">Correspondance des colonnes</h5>
            <div className="textHelper">
              Indiquer le numéro des colonnes contenant les valeurs nécessaires à l&apos;import. "0" permet d'omettre
              une colonne.
            </div>

            <div className="form-container">
              <div className="margin-bottom-1">
                <Checkbox
                  defaultChecked
                  onChange={this.switchHeaderIgnored}
                >
                  Ignorer l&apos;entête lors de l&apos;import
                </Checkbox>
              </div>
              {valuesOfTypeOfData.values.map((value, index) => (
                <SelectColumImport
                  key={value.id}
                  defaultValue={index + 1}
                  handleColumnChange={this.handleColumnChange}
                  column={value.column}
                  name={value.name}
                  id={value.id}
                  max={maxValueOfInput}
                  couponColumnIsDisabled={this.couponColumnIsDisabled}
                />
              ))}
              <form className="import-buttons-container" onSubmit={this.handleSubmitedCSVFiles}>
                <UploadFile
                  typeOfData={typeOfData}
                  file={files}
                  valuesOfTypeOfData={valuesOfTypeOfData}
                  submit={this.handleSubmitedCSVFiles}
                />
                <SimpleModal
                  files={filesPreview}
                  valuesHeader={valuesOfTypeOfData}
                  headerIgnored={headerIgnored}
                  couponColumnDisabled={couponColumnDisabled}
                />
              </form>
            </div>
          </div>
        </div>
      );
    }
    return <div/>;
  }

  render() {
    const { files, ongletSwitchButton, dataTypeSelected } = this.state;
    return (
      <div className="viewContainer">
        <h1 className="title">Importer des données</h1>

        <div className="row blockContainer small switchContainer">
          <div className="menuSwitch">
            {ongletSwitchButton.map(button => (
              <button
                className={`menuSwitch-item ${dataTypeSelected === button.value ? 'active' : ''}`}
                value={button.value}
                onClick={this.getConstanteForImport}
                type="button"
                key={button.value}
              >
                {button.text}
              </button>
            ))}
          </div>

          <div className="switchContainer-content columns auto tableContainer small">
            <FileImporter
              files={files}
              type={dataTypeSelected}
              handleChange={this.handleChange}
              onFileDelete={this.onFileDelete}
              ref={fileImporterRef => {
                this.fileImporterRef = fileImporterRef;
              }}
            />
            {this.toggleOptionsAfterCSVParse()}
          </div>
        </div>
      </div>
    );
  }
}

export default ImportPage;
