export default function getParameterForFetch(type, body) {
  const parameters = {
    crossDomain: true,
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    method: type,
  };
  if (body) {
    parameters.body = JSON.stringify(body);
  }
  return parameters;
}
