import React from 'react';
import propTypes from 'prop-types';
import './FileImporter.scss';
import { DropzoneArea } from 'material-ui-dropzone';

class FileImporter extends React.Component {



  render() {
    const { handleChange, onFileDelete, type } = this.props;

    return (
      <div>
        <h5 className="switchContainer-content-subtitle">Choix des fichiers</h5>

        {type === 'SOR' ? (
          <div className="import-helper-text">
            Veuillez déposer ici les fichiers .SOR dans lesquels se trouvent les valeurs des taux de
            conversion que vous souhaitez importer.
            <br/>
            Ces fichiers doivent être homogènes (toutes les colonnes sont les mêmes).
          </div>
        ) : (
          <div className="import-helper-text">
            Veuillez déposer ici les fichiers .csv dans lesquels se trouvent les valeurs des taux de
            conversion que vous souhaitez importer.
            <br/>
            Ces fichiers doivent être homogènes (toutes les colonnes sont les mêmes).
          </div>
        )}

        <div className="DropZoneArea-display-custom">
          <DropzoneArea
            dropzoneclass="DropZoneArea-display-custom flex"
            onChange={handleChange}
            dropzoneText={type === "SOR" ? "Choisissez des fichiers .SOR à importer ou déposez-les ici" : "Choisissez des fichiers .csv à importer ou déposez-les ici"}
            acceptedFiles={type === "SOR" ? ['']: ['text/csv, application/vnd.ms-excel']}
            ref={dropzoneRef => {
              this.dropzoneRef = dropzoneRef;
            }}
            showPreviews
            showPreviewsInDropzone={false}
            showFileNamesInPreview
            filesLimit={3}
            name="file"
            onDelete={onFileDelete}
            showAlerts={false}
          />
        </div>
      </div>
    );
  }
}

export default FileImporter;

FileImporter.propTypes = {
  handleChange: propTypes.func.isRequired,
};
