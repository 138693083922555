import React from 'react';
import Modal from '@material-ui/core/Modal';
import './modal.scss';

import { useTranslate } from 'react-translate';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TablePrevisualisation from './TablePrevisualisation';

function SimpleModal(props) {
  const t = useTranslate('translation');
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { files, valuesHeader, headerIgnored, couponColumnDisabled } = props;
  return (
    <div>
      <button type="button" onClick={handleOpen} className="ant-btn secondary button ripple">
        {t('PREVISUALISATION')}
      </button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className="previsualisation-modal-container">
          <div className="previsualisation-modal-content">
            <IconButton aria-label="close" className="close-modal-button" onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            <div className="ant-modal-header">
              <div className="ant-modal-title">{t('PREVISUALISATION_DATA')}</div>
              {files.map((filePreview, index) => (
                <div className="previsualisation-modal-file-container" key={index}>
                  <TablePrevisualisation
                    fileName={filePreview.name}
                    rows={filePreview.data}
                    valuesHeader={valuesHeader}
                    headerIgnored={headerIgnored}
                    couponColumnDisabled={couponColumnDisabled}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SimpleModal;
