import React from 'react';

import { useTranslate } from 'react-translate';
import OpcIndiceComposite from '../OpcIndiceComposite/OpcIndiceComposite';
import OpcCaracteristique from '../OpcCaracteristique/OpcCaracteristique';

function OpcCreation() {
  const t = useTranslate('translation');
  const url = 'api/opc/saveNewOpc';
  return (
    <div className="opc">
      <div>
        <h3 className="title">{t('OPC_MANAGER_TITLE')}</h3>
      </div>
      <OpcCaracteristique url={url} />
      <OpcIndiceComposite />
    </div>
  );
}

export default OpcCreation;
