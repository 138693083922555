/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, Fragment } from 'react';
import {Switch, Tooltip, message, Modal, DatePicker, Button} from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { useTranslate } from 'react-translate';
import Months from '../../../helpers/const/Months';
import { API_SERVEUR } from '../../../helpers/const/API';
import './OpcCaracteristique.scss';

import getParameterForFetch from '../../../helpers/function/paramFetch';
import handleServerResponse from '../../../helpers/reponseManager/reponseHandler';

function OpcCaracteristique(props) {
  const { url, setOpcValue, history } = props;
  const t = useTranslate('translation');
  const [OpcSelectedValues, SetOpcValues] = useState({});
  function handleOpcStatusChange(checked) {
    SetOpcValues({ ...OpcSelectedValues, actif: checked });
  }

  useEffect(() => {
    if (history.location.state.isNew) {
      return SetOpcValues({});
    } else {
      return SetOpcValues(history.location.state.opc);
    }
  }, [history.location.state.isNew, history.location.state.opc]);

  function handleOpcValuesChange(e) {
    if (e.target.value === null || e.target.value === '') {
      return delete OpcSelectedValues[e.target.name];
    }
    SetOpcValues({ ...OpcSelectedValues, [e.target.name]: e.target.value });
  }
  function getOpcByID() {}

  function saveOpc(e) {
    e.preventDefault();
    const fullUrl = `${API_SERVEUR}${url}`;
    const body = OpcSelectedValues;
    const parameters = getParameterForFetch('POST', body);
    fetch(fullUrl, parameters)
      .then(res => res.json())
      .then(res => {
        if (res.data) {
          handleServerResponse(res);
          setOpcValue(res.data[0]);
          SetOpcValues(res.data);
          history.replace(`/gestionOpc?codeIsin=${OpcSelectedValues.codeIsin}`, {
            opc: res.data[0],
            isNew: false,
          });
        } else {
          message.warning('Ce code ISIN est déjà utilisé');
        }
      })
      .then(() => getOpcByID());
  }
  useEffect(() => {
    if (history.location.state.isNew) {
      return;
    }
    const fullUrl = `${API_SERVEUR}api/opc/getOpcById`;
    let body;
    if (history.location.state.opc) {
      body = { _id: history.location.state.opc._id };
    }

    const parameters = getParameterForFetch('POST', body);
    fetch(fullUrl, parameters)
      .then(res => res.json())
      .then(res => {
        SetOpcValues(res.data);
      });
  }, [history.location.state.isNew, history.location.state.opc]);

  useEffect(() => {
    if (OpcSelectedValues) {
      const lengthOfOpcObject = Object.keys(OpcSelectedValues).length;
      if (lengthOfOpcObject >= 10) {
        setMessageForSaveOpc(t('OPC_IS_READY_TO_SAVE'));
        return setEnableSaveOpc(false);
      } else if (lengthOfOpcObject < 12) {
        setMessageForSaveOpc(t('PLEASE_FILL_THE_OPC'));
        return setEnableSaveOpc(true);
      }
    }
  }, [OpcSelectedValues, t]);

  const getMatchingMonthOfClosing = monthNumber => {
    if (!monthNumber) {
      return '';
    }
    const month = Months.find(Month => Month.value === monthNumber);
    if (!month) {
      return;
    }
    return month.name;
  };

  const purgeVL = () => {
    const fullUrl = API_SERVEUR + 'api/liquidationValue/deleteLiquidationValueByOpcIsinForPeriod?codeIsin=' +
      OpcSelectedValues.codeIsin + '&from=' + moment(fromPeriod).format('YYYY-MM-DD')
    + '&to=' + moment(toPeriod).format('YYYY-MM-DD');
    const parameters = getParameterForFetch('DELETE', {});
    fetch(fullUrl, parameters)
      .then(res => {
        if (res.status === 200) {
          message.success("Les valeurs ont bien été supprimées");
          setPurgeModalVisible(false);
          //window.location.reload();
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        message.error("Le système a rencontré une erreur lors de la purge");
        setPurgeModalVisible(false);
      })

  };

  const [messageForPurge, setMessageForPurge] = useState(t('PURGE_VL'));
  const [messageForSaveOpc, setMessageForSaveOpc] = useState(t('PLEASE_FILL_THE_OPC'));
  const [enableSaveOpc, setEnableSaveOpc] = useState(true);
  const [purgeModalVisible, setPurgeModalVisible] = useState(false);
  const [fromPeriod, setFromPeriod] = useState(new Date());
  const [toPeriod, setToPeriod] = useState(new Date());

  return (
    <div className="blockContainer">
      <h4 className="subtitle">{t('OPC_CARACTERISTIQUE')}</h4>
      <form>
        <Switch
          className="switchButton"
          checked={OpcSelectedValues ? OpcSelectedValues.actif : false}
          defaultChecked={false}
          onClick={handleOpcStatusChange}
          name="actif"
          checkedChildren="Actif"
          unCheckedChildren="Inactif"
        />
        <div className="flex">
          <div className="opc-column-1">
            <div>
              <label>{t('CODE_ISIN')}</label>
              <input
                type="text"
                defaultValue={OpcSelectedValues ? OpcSelectedValues.codeIsin : ''}
                onChange={handleOpcValuesChange}
                name="codeIsin"
                required
                className="opc-input"
              />
            </div>

            <div>
              <label>{t('LABEL')}</label>
              <input
                type="text"
                defaultValue={OpcSelectedValues ? OpcSelectedValues.label : ''}
                onChange={handleOpcValuesChange}
                name="label"
                required
              />
            </div>

            <div>
              <label>{t('OPCVM')}</label>
              <input
                type="text"
                defaultValue={OpcSelectedValues ? OpcSelectedValues.valorisation : ''}
                onChange={handleOpcValuesChange}
                name="valorisation"
                required
              />
            </div>

            <div>
              <label>{t('NATURE')}</label>
              <select onChange={handleOpcValuesChange} name="nature" required>
                <option>{OpcSelectedValues ? OpcSelectedValues.nature : ''}</option>
                <option>{t('CLASSIC')}</option>
                <option>{t('INDICIEL')}</option>
                <option>{t('FORMULE')}</option>
              </select>
            </div>

            <div>
              <label>{t('DEVISE')}</label>
              <input
                type="text"
                onChange={handleOpcValuesChange}
                name="devise"
                required
                maxLength="3"
                defaultValue={OpcSelectedValues ? OpcSelectedValues.devise : ''}
              />
            </div>
          </div>

          <div className="opc-column-2">
            <div>
              <label>{t('FISCAL_TYPE')}</label>
              <select onChange={handleOpcValuesChange} name="fiscalType" required>
                <option>{OpcSelectedValues ? OpcSelectedValues.fiscalType : ''}</option>

                <option>{t('CAPITALISE')}</option>
                <option>{t('DETACHE')}</option>
              </select>
            </div>

            <div>
              <label>{t('STOCK_MARKET_TYPE')}</label>
              <select onChange={handleOpcValuesChange} name="typeOfStockMarket" required>
                <option>
                  {OpcSelectedValues && OpcSelectedValues.typeOfStockMarket
                    ? t(`${OpcSelectedValues.typeOfStockMarket.toUpperCase()}`)
                    : ''}
                </option>

                <option value="firstStockMarket">{t('FIRSTSTOCKMARKET')}</option>
                <option value="lastStockMarket">{t('LASTSTOCKMARKET')}</option>
                <option value="lowestStockMarket">{t('LOWESTSTOCKMARKET')}</option>
                <option value="highestStockMarket">{t('HIGHESTSTOCKMARKET')}</option>
              </select>
            </div>

            <div>
              <label>{t('ANALYSE_ECART')}</label>
              <input
                defaultValue={OpcSelectedValues ? OpcSelectedValues.percentageDif : ''}
                type="number"
                onChange={handleOpcValuesChange}
                name="percentageDif"
                required
              />
            </div>

            <div>
              <label>{t('SEUIL_CORRELATION')}</label>
              <input
                defaultValue={OpcSelectedValues ? OpcSelectedValues.coefCorrelation : ''}
                type="number"
                onChange={handleOpcValuesChange}
                name="coefCorrelation"
                required
              />
            </div>

            <div>
              <label>{t('SEUIL_ERROR')}</label>
              <input
                defaultValue={OpcSelectedValues ? OpcSelectedValues.maxTrackingError : ''}
                type="number"
                onChange={handleOpcValuesChange}
                name="maxTrackingError"
                required
              />
            </div>
          </div>

          <div className="opc-column-3">
            <div>
              <label>{t('MONTH_CLOTURE')}</label>
              <select
                defaultValue={OpcSelectedValues ? OpcSelectedValues.closingMonth : ''}
                onChange={handleOpcValuesChange}
                name="closingMonth"
                required
              >
                <option>
                  {OpcSelectedValues
                    ? getMatchingMonthOfClosing(OpcSelectedValues.closingMonth)
                    : ''}
                </option>
                {Months.map(month => (
                  <option key={month.value} value={month.value}>
                    {month.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label>{t('AUDIT_FREQUENCE')}</label>
              <select onChange={handleOpcValuesChange} name="frequence" required>
                <option>{OpcSelectedValues ? OpcSelectedValues.frequence : ''}</option>
                <option>{t('TRIMESTRE')}</option>
                <option>{t('SEMESTRE')}</option>
                <option>{t('ANNUEL')}</option>
              </select>
            </div>
          </div>
        </div>

        <div className="buttonContainer flexButtonContainer">
          <Tooltip title={messageForPurge} placement="right">
            <Button
              className="outlineSecondary button ripple"
              type="secondary"
              icon="plus"
              size="large"
              onClick={e => setPurgeModalVisible(true)}>
              {t('PURGE')}
            </Button>
          </Tooltip>
          <Tooltip title={messageForSaveOpc} placement="left">
            <button
              className="button secondary"
              onClick={e => saveOpc(e)}
              type="submit"
              disabled={enableSaveOpc}
            >
              {t('SAVE')}
            </button>
          </Tooltip>
        </div>
      </form>
      <Modal
        title={t('VL_PURGE')}
        visible={purgeModalVisible}
        okText={t('CONFIRM_PURGE')}
        cancelText={t('CANCEL')}
        onOk={() => purgeVL()}
        onCancel={() => setPurgeModalVisible(false)}
      >
        <Fragment>
          <div>
            <p>{t('PERIOD_TO_PURGE')}</p>
          </div>
          <div className="newComposite-content">
            <div className="datePickerContainer">
              <p className="margin-bottom-0">{t('BETWEEN')}</p>
              <DatePicker
                className="firstApplicationDate"
                format="DD-MM-YYYY"
                placeholder="Choisir une date"
                onChange={e => setFromPeriod(e)}
              />
            </div>
            <div className="datePickerContainer">
              <p className="margin-bottom-0">{t('AND')}</p>
              <DatePicker
                className="firstApplicationDate"
                format="DD-MM-YYYY"
                placeholder="Choisir une date"
                onChange={e => setToPeriod(e)}
              />
            </div>
          </div>
        </Fragment>
      </Modal>
    </div>
  );
}

export default withRouter(OpcCaracteristique);
