import { useState } from 'react';

const RegisterUserHook = callback => {
  const [inputs, setInputs] = useState({});
  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };
  const handleInputChange = event => {
    event.persist();
    setInputs(() => ({ ...inputs, [event.target.name]: event.target.value }));
  };
  return { handleSubmit, handleInputChange, inputs };
};
export default RegisterUserHook;
