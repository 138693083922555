import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';

const ProtectedRoute = props => {
  const { path, userIsLogged, history } = props;
  return (
    <Route
      exact
      path={path}
      render={() => {
        if (userIsLogged) {
          return <props.component {...props} history={history} />;
        } else {
          return <Redirect to="/" exact />;
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
};
export default withRouter(ProtectedRoute);
