import UserManager from '../../Page/UserManager/UserManagerPage';
import UserListManager from '../../Page/UserManager/UserListManager';

const routesAdmin = [
  {
    id: 18,
    value: 'UserGestion',
    path: '/admin/gestion',
    icon: 'icon-display',
    component: UserListManager,
  },
  {
    id: 19,
    value: 'UserRegister',
    path: '/admin/register',
    icon: 'icon-home',
    component: UserManager,
  },
];
export default routesAdmin;
