import React from 'react';

import { DatePicker } from 'antd';
import { useTranslate } from 'react-translate';

function DatePickerConversion(props) {
  const t = useTranslate('translation');
  const { date } = props;
  const updateDate = (field, value) => {
    props.setDate({
      ...props.date,
      [field]: value,
    });
  };

  const onStartChange = value => {
    updateDate('startDate', value);
  };

  const onEndChange = value => {
    updateDate('endDate', value);
  };
  return (
    <div>
      <span className="beforeDatePicker">{t('VALUE_BETWEEN')}</span>
      <DatePicker
        format="DD-MM-YYYY"
        defaultValue={date.startDate}
        value={date.startDate}
        placeholder="Date de début"
        name="startdate"
        onChange={onStartChange}
      />
      <span className="betweenDatePicker">{t('AND_THE')}</span>
      <DatePicker
        format="DD-MM-YYYY"
        defaultValue={date.endDate}
        value={date.endDate}
        placeholder="Date de fin"
        name="enddate"
        onChange={onEndChange}
      />
    </div>
  );
}

export default DatePickerConversion;
