/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useTranslate } from 'react-translate';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Tabs, Button, message, DatePicker } from 'antd';
import getParameterForFetch from '../../../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../../../helpers/const/API';
import handleServerResponse from '../../../../helpers/reponseManager/reponseHandler';
import OpcModalIndice from '../../OpcModalIndice/OpcModalIndice';
import './OpcHistoryComposite.scss';

const { TabPane } = Tabs;

function OpcHistoryComposite(props) {
  const t = useTranslate('translation');
  const { opc, forceUpdate, setForceUpdate, history } = props;

  const [associatedIndicesComposites, setAssociatedIndicesComposites] = useState([]);

  const getCompositeAssociatedWithOpcIsinCode = useCallback(() => {
    const id = opc._id || history.location.state.opc._id;
    const parametersForRetrieveAssociatedIndiceComposite = getParameterForFetch('POST', {
      id,
    });
    const url = `${API_SERVEUR}api/composite/getIndiceCompositeByOpcCodeIsin`;
    fetch(url, parametersForRetrieveAssociatedIndiceComposite)
      .then(res => res.json())
      .then(res => setAssociatedIndicesComposites(res))
      .then(() => setForceUpdate(false));
  }, [history.location.state.opc._id, opc._id, setForceUpdate]);

  useEffect(() => {
    getCompositeAssociatedWithOpcIsinCode();
  }, [forceUpdate, getCompositeAssociatedWithOpcIsinCode]);
  useEffect(() => {
    getCompositeAssociatedWithOpcIsinCode();
  }, [getCompositeAssociatedWithOpcIsinCode]);

  function updateComposite(indiceCompositeToUpdate) {
    const allWeight = indiceCompositeToUpdate.indice.map(indice => parseFloat(indice.weight));
    if (allWeight.reduce((a, b) => a + b, 0) !== 100) {
      message.error('Le poids total est différent de 100');
      getCompositeAssociatedWithOpcIsinCode();
    }
    const indiceToUpdateInsideComposite = formateIndiceCompositeToUpdate(indiceCompositeToUpdate);
    const url = `${API_SERVEUR}api/composite/updateComposite`;
    const body = getParameterForFetch('POST', indiceToUpdateInsideComposite);
    fetch(url, body)
      .then(res => res.json())
      .then(res => handleServerResponse(res))
      .then(() => getCompositeAssociatedWithOpcIsinCode());
  }

  function formateIndiceCompositeToUpdate(indiceCompositeToUpdate) {
    const arrayOfIndiceForIndiceComposite = [];
    indiceCompositeToUpdate.indice.map(indices => arrayOfIndiceForIndiceComposite.push({ _id: indices._id, indice: indices.indice, weight: indices.weight }))
    return {
      _id: indiceCompositeToUpdate._id,
      firstApplicationDate: indiceCompositeToUpdate.firstApplicationDate,
      opc: indiceCompositeToUpdate.opc,
      indice: arrayOfIndiceForIndiceComposite,
    };
  }

  const [editMode, setEditMode] = useState(false);
  const [indiceCompositeToUpdate, setindiceCompositeToUpdate] = useState();

  function ifEditMode(ref, refName) {
    if (editMode) {
      return (
        <input
          type="number"
          name={refName}
          placeholder={ref[refName]}
          onChange={e => handleIndiceChangesEdit(e, ref, refName)}
        />
      );
    }
    return <p>{ref[refName]}</p>;
  }

  function handleUpdateIndiceDateChange(values) {
    const copy = Object.assign({}, indiceCompositeToUpdate);
    Object.assign(copy, { firstApplicationDate: new Date(values) });
    setindiceCompositeToUpdate(copy);
  }

  function handleDeleteExisting(indices) {
    const copy = Object.assign({}, indiceCompositeToUpdate);
    copy.indice = indiceCompositeToUpdate.indice.filter(
      indiceOfComposite => indiceOfComposite.indice._id !== indices._id,
    );
    setindiceCompositeToUpdate(copy);
  }

  const [isModalIndiceOpen, setModalIndiceOpen] = useState(false);
  const [indicesForComposites, setIndicesForComposite] = useState([]);

  function getIndices() {
    const parameters = getParameterForFetch('POST');
    const url = `${API_SERVEUR}api/indice/retrieveIndice`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setIndicesForComposite(res));
  }

  function addIndiceToComposite(values) {
    const copy = Object.assign({}, indiceCompositeToUpdate);
    values.forEach(value => copy.indice.push({ indice: value }));

    setindiceCompositeToUpdate(copy);
  }

  function handleIndiceChangesEdit(e, ref, refName) {
    const indexToUpdate = indiceCompositeToUpdate.indice.findIndex(
      indice => indice._id === ref._id,
    );
    indiceCompositeToUpdate.indice[indexToUpdate][refName] = e.target.value;
  }

  return (
    <div className="tableSize">
      {associatedIndicesComposites.length ? (
        <Tabs defaultActiveKey={associatedIndicesComposites[0]._id} tabPosition="left">
          {associatedIndicesComposites.map((indiceComposite, index) => (
            <TabPane
              tab={`Indice composite du ${moment(indiceComposite.firstApplicationDate).format(
                'DD-MM-YYYY',
              )}`}
              key={indiceComposite._id}
              disabled={editMode}
            >
              <div className="moreAction">
                {!editMode && index === 0 ? (
                  <Fragment>
                    <Button
                      className="secondary button ripple"
                      type="secondary"
                      size="large"
                      onClick={() => {
                        setindiceCompositeToUpdate(indiceComposite);
                        setEditMode(!editMode);
                      }}
                      icon="edit"
                    />
                  </Fragment>
                ) : (
                    <Fragment />
                  )}
                {editMode ? (
                  <Fragment>
                    <DatePicker
                      defaultValue={moment(indiceCompositeToUpdate.firstApplicationDate)}
                      onChange={value => handleUpdateIndiceDateChange(value)}
                    />
                    <Button
                      className="button primary"
                      type="primary"
                      icon="plus"
                      onClick={() => {
                        getIndices();
                        setModalIndiceOpen(!isModalIndiceOpen);
                      }}
                    >
                      {t('ADD_TO_COMPOSITE')}
                    </Button>
                  </Fragment>
                ) : (
                    <Fragment />
                  )}
              </div>

              <table>
                <thead>
                  <tr>
                    <th className="medium-size">{t('CODE_ISIN')}</th>
                    <th className="large-size tabLabel">{t('LABEL')}</th>
                    <th className="large-size tabCode">{t('COTATION_LOCATION')}</th>
                    <th className="small-size">{t('DEVISE')}</th>
                    <th className="small-size">{t('WEIGHT')}</th>
                    {editMode ? <th /> : <Fragment />}
                  </tr>
                </thead>
                <tbody>
                  {editMode ? (
                    indiceCompositeToUpdate.indice.map(indice => (
                      <Fragment key={indice.indice._id}>
                        <tr key={indice.indice._id}>
                          <td className="medium-size">{indice.indice.codeIsin}</td>
                          <td className="large-size tabLabel">{indice.indice.label}</td>
                          <td className="large-size tabCode">{indice.indice.cotationLocation}</td>
                          <td className="small-size">{indice.indice.devise}</td>
                          <td className="small-size no-margin-bottom">
                            {ifEditMode(indice, 'weight')}
                          </td>
                          <td>
                            <button
                              className="roundButton roundSecondary"
                              type="button"
                              onClick={() => handleDeleteExisting(indice.indice)}
                            >
                              <i className="icon-bin" />
                            </button>
                          </td>
                        </tr>
                      </Fragment>
                    ))
                  ) : indiceComposite.indice.length ? (
                    indiceComposite.indice.map(indice => (
                      <Fragment key={indice.indice._id}>
                        <tr key={indice.indice._id}>
                          <td className="medium-size">{indice.indice.codeIsin}</td>
                          <td className="large-size tabLabel">{indice.indice.label}</td>
                          <td className="large-size tabCode">{indice.indice.cotationLocation}</td>
                          <td className="small-size">{indice.indice.devise}</td>
                          <td className="small-size weightMargin">{ifEditMode(indice, 'weight')}</td>
                        </tr>
                      </Fragment>
                    ))
                  ) : (
                        <Fragment />
                      )}
                </tbody>
              </table>
              {editMode ? (
                <div className="text-right buttonContainer">
                  <button
                    type="button"
                    className="ant-btn secondary button ripple"
                    onClick={() => {
                      updateComposite(indiceCompositeToUpdate);
                      setEditMode(!editMode);
                      getCompositeAssociatedWithOpcIsinCode();
                    }}
                    icon="save"
                  >
                    {t('SAVE')}
                  </button>
                </div>
              ) : (
                  <div />
                )}
            </TabPane>
          ))}
        </Tabs>
      ) : (
          <div />
        )}
      <OpcModalIndice
        isModalIndiceOpen={isModalIndiceOpen}
        setModalIndiceOpen={setModalIndiceOpen}
        indicesForComposites={indicesForComposites}
        setIndiceComposite={addIndiceToComposite}
      />
    </div>
  );
}

export default withRouter(OpcHistoryComposite);
