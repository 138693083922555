import React, { useState } from 'react';
import moment from 'moment';
import { useTranslate } from 'react-translate';
import { Link } from 'react-router-dom';
import { Input, Button, Icon } from 'antd';
import DatePickerConversion from '../ConversionPage/DatePickerConversion/DatePicker';
import ReferentielPageBody from './ReferentielPageBody/ReferentielPageBody';
import './Referentiel.scss';

function ReferentielPage() {
  const t = useTranslate('translation');

  const [date, setDate] = useState({
    startDate: moment().startOf('year'),
    endDate: moment(),
  });
  const [inputValue, setInputValue] = useState('');
  const handleChangeInput = e => {
    const valueInput = e.target.value;
    setInputValue(valueInput);
  };
  const propertyBody = {
    search: inputValue,
    startDate: date.startdate,
    endDate: date.enddate,
  };

  return (
    <div className="viewContainer">
      <header className="flex conversion-page-header header">
        <h1 className="title">{t('TITLE_REFERENTIEL')}</h1>
      </header>

      <div className="inputContainer text-right">
        <Input
          value={inputValue}
          onChange={e => handleChangeInput(e)}
          className="conversion-page-search"
          placeholder={t('SEARCH_INDICE')}
          prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
          suffix={<Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />}
        />
      </div>

      <div className="blockContainer medium">
        <div className="actionElement">
          <DatePickerConversion setDate={setDate} date={date} />
          <Link to="/newIndice">
            <Button className="primary button ripple" type="primary" icon="plus" size="large">
              {t('ADD_INDICE')}
            </Button>
          </Link>
        </div>

        <ReferentielPageBody propertyBody={propertyBody} period={date} />
      </div>
    </div>
  );
}

export default ReferentielPage;
