import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';

function RouteAdmin(props) {
  const { path, isUserAdmin, history } = props;
  return (
    <Route
      exact
      path={path}
      render={() => {
        if (isUserAdmin()) {
          return <props.component {...props} history={history} />;
        } else {
          return <Redirect to="/accueil" exact />;
        }
      }}
    />
  );
}

RouteAdmin.propTypes = {
  path: PropTypes.string.isRequired,
  isUserAdmin: PropTypes.func.isRequired,
};

export default withRouter(RouteAdmin);
