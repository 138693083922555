/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-translate';
import './ReferentielPage.scss';

import { Link } from 'react-router-dom';
import { Button } from 'antd';
import getParameterForFetch from '../../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../../helpers/const/API';
import handleServerResponse from '../../../helpers/reponseManager/reponseHandler';

function ReferentielPageNew(props) {
  const { history } = props;
  const t = useTranslate('translation');
  const [newIndice, setNewIndice] = useState({
    source: true,
  });

  useEffect(() => {
    if (history.location.state && history.location.state.indice) {
      return setNewIndice(history.location.state.indice);
    } else {
      return setNewIndice({
        source: true,
      });
    }
  }, [history.location]);

  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const enabled = () => {
    if (newIndice.codeIsin && newIndice.cotationLocation && newIndice.devise && newIndice.label) {
      setEnableSaveButton(false);
    }
  };

  function handlePropertyChange(e) {
    setNewIndice(Object.assign(newIndice, { [e.target.name]: e.target.value }));
    enabled();
  }

  function saveIndice() {

    const params = newIndice._id ? getParameterForFetch('PUT', newIndice) : getParameterForFetch('POST', newIndice);
    const url = newIndice._id ? `${API_SERVEUR}api/indice/edit` : `${API_SERVEUR}api/indice/new`;



    fetch(url, params)
      .then(res => res.json())
      .then(res => handleServerResponse(res))
      .then(() => history.push('/referentiel'));
  }


  return (
    <div className="viewContainer">
      <h1 className="title">{newIndice._id ? t('EDIT_INDICE') : t('ADD_NEW_INDICE')}</h1>

      <div className="row blockContainer small">
        <div className="columns small-10 referentialContainer">
          <div>
            <div>
              <label>{t('CODE_ISIN')}</label>
              <input type="text"
                     name="codeIsin"
                     disabled={newIndice.numberOfOpcUsingIndice > 0}
                     defaultValue={newIndice.codeIsin}
                     onChange={handlePropertyChange} />
            </div>

            <div>
              <label>{t('LABEL')}</label>
              <input type="text"
                     name="label"
                     defaultValue={newIndice.label}
                     onChange={handlePropertyChange} />
            </div>

            <div>
              <label>{t('COTATION_LOCATION')}</label>
              <input name="cotationLocation"
                     defaultValue={newIndice.cotationLocation}
                     onChange={handlePropertyChange}
                     type="number" />
            </div>
            <div>
              <label>{t('DEVISE')}</label>
              <input type="text"
                     name="devise"
                     disabled={newIndice.numberOfOpcUsingIndice > 0}
                     defaultValue={newIndice.devise}
                     onChange={handlePropertyChange} />
            </div>
          </div>

          <Link className="buttonContainer">
            <Button
              className="outlineSecondary button ripple right-8"
              onClick={() => history.push('/referentiel')}
            >
              {t('CANCEL')}
            </Button>

            <Button
              className="secondary button ripple"
              type="secondary"
              icon="plus"
              onClick={saveIndice}
              disabled={enableSaveButton}
            >
              {newIndice._id ? t('EDIT') : t('ADD')}
            </Button>
          </Link>
        </div>

        <div className="columns auto referentialImg">&nbsp;</div>
      </div>
    </div>
  );
}

export default ReferentielPageNew;
