/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslate } from 'react-translate';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Spin, message } from 'antd';
import getParameterForFetch from '../../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../../helpers/const/API';
import './AuditResult.scss';
import AuditResultCsvExport from './AuditResultCsvExport/AuditResultCsvExport';
import AuditResultModalMissingData from './AuditResultModalMissingData/AuditResultModalMissingData';
import Months from '../../../helpers/const/Months';

function AuditResult(props) {
  const t = useTranslate('translation');
  const { location } = props;
  const [OpcValues, setOpcValues] = useState({ codeIsin: null });
  const [period] = useState({
    startDate: location.state.startDate || location.state.audit.startDate,
    endDate: location.state.endDate || location.state.audit.endDate,
    realisationDate: location.state.realisationDate || location.state.audit.realisationDate,
  });

  useEffect(() => {
    getOpcByCodeISIN();
  }, []);

  const getMatchingMonthOfClosing = monthNumber => {
    if (!monthNumber) {
      return '';
    }
    const month = Months.find(Month => Month.value === monthNumber);

    return month.name;
  };

  const getOpcByCodeISIN = () => {
    const body = getParameterForFetch('POST', {
      codeIsin: location.state.codeIsin || location.state.opc.opc.codeIsin,
    });
    const url = `${API_SERVEUR}api/opc/getOpcByCodeISIN`;
    fetch(url, body)
      .then(res => res.json())
      .then(res => setOpcValues(res));
  };

  const [resultAudit, setResultAudit] = useState([]);
  useEffect(() => {
    retrieveResultAudit();
  }, []);
  useEffect(() => {
    if (OpcValues.codeIsin) {
      getLasttKnowLiquidativeValueOfOpc();
      getLastValueOfReferenceForOpc();
    }
  }, [OpcValues]);

  useEffect(() => {
    setfileName(`${OpcValues.codeIsin}   ${moment(resultAudit.reduce).format('DD-MM-YYYY')}.csv`);
  }, [resultAudit]);


  const [lastKnowValueOfClosingMonth, setLastKnowValueOfClosingMonth] = useState({});
  const [
    lastKnowLiquidativeValueForOpcBeforePeriod,
    setLastKnowLiquidativeValueForOpcBeforePeriod,
  ] = useState({});


  function getLastValueOfReferenceForOpc() {
    const body = { codeIsin: OpcValues.codeIsin, closingMonth: OpcValues.closingMonth, period };
    const parameters = getParameterForFetch('POST', body);
    const url = `${API_SERVEUR}api/liquidationValue/getLastValueOfReferenceForOpc`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setLastKnowValueOfClosingMonth(res[0] || {}));
  }

  function getLasttKnowLiquidativeValueOfOpc() {
    const body = { codeIsin: OpcValues.codeIsin, period };
    const parameters = getParameterForFetch('POST', body);
    const url = `${API_SERVEUR}api/liquidationValue/getLasttKnowLiquidativeValueOfOpc`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setLastKnowLiquidativeValueForOpcBeforePeriod(res[0] || {}));
  }

  const [fileName, setfileName] = useState('');

  function retrieveResultAudit() {
    const valuesForRetrieveAuditResult = {};
    valuesForRetrieveAuditResult.period = period;
    valuesForRetrieveAuditResult.codeIsin = location.state.codeIsin || location.state.opc.opc.codeIsin;
    valuesForRetrieveAuditResult.opc = location.state.id || location.state.opc.opc._id || OpcValues._id;
    const parameters = getParameterForFetch('POST', valuesForRetrieveAuditResult);
    const url = `${API_SERVEUR}api/auditResult/createAuditResult`;
    try {
      fetch(url, parameters)
        .then(res => res.json())
        .then(res => {
            if (res.success) {
              setResultAudit(res.data);
            } else {
              message.error(res.message, 10);
              setResultAudit([]);
            }
          },
        );
    } catch (error) {
      message.error('Un problème est survenu lors de l\'audit, veuillez vérifier les dates choisies');
    }
  }

  function requestMissingData() {
    const url = `${API_SERVEUR}api/valorisationRequest/registerMissingDataRequests`;
    const parameters = getParameterForFetch('POST', resultAudit.missingData);
    fetch(url, parameters)
      .then(() => message.success('La requête de données manquantes a été soumise'))
      .then(() => retrieveResultAudit())
      .catch(() => message.error('Une erreur s\'est produite lors de la requête'));
  }


  const transformToValidDate = dateToTransform => {
    return moment(new Date(dateToTransform)).format('DD-MM-YYYY');
  };

  const [dataToExport, setdataToExport] = useState([]);

  useEffect(() => {
    const dataToExportCSV = [];
    if (lastKnowValueOfClosingMonth) {
      dataToExportCSV.push(lastKnowValueOfClosingMonth);
    }
    if (lastKnowLiquidativeValueForOpcBeforePeriod) {
      dataToExportCSV.push(lastKnowLiquidativeValueForOpcBeforePeriod);
    }
    if (resultAudit.resultAudit) {
      resultAudit.resultAudit.forEach(resultData => dataToExportCSV.push(resultData));
    }
    setdataToExport(dataToExportCSV);
  }, [lastKnowValueOfClosingMonth, lastKnowLiquidativeValueForOpcBeforePeriod, resultAudit]);


  return (
    <div className="auditResult">
      <div>
        <h1 className="title">
          {t('AUDIT_OPC')}:{`${OpcValues.codeIsin}- ${OpcValues.label}`}
        </h1>
      </div>
      <div className="hide-for-print">
        {resultAudit.resultAudit ? (
          <Fragment>
            <AuditResultCsvExport dataToExport={dataToExport} filename={fileName}/>
            <button className="button primary margin-left-20px" onClick={requestMissingData}
                    disabled={resultAudit.missingData.every(missing => !missing.codeIsin || missing.status === 'REQUESTED' || missing.status === 'PROCESSED')}>
              {t('DOWNLOAD_SIX_FILES')}
            </button>
          </Fragment>
        ) : (
          <div/>
        )}
      </div>

      <div className="row blockContainer auto">
        <fieldset className="columns small-12 aboutContainer">
          <legend>A propos de l&apos;audit</legend>
          <div className="row datePickerContainer first">
            <span className="columns small-8">{t('DATE_REALISATION_AUDIT')} :</span>
            <span className="columns shrink">
              {moment(resultAudit.realisationDate).format('DD-MM-YYYY')}
            </span>
          </div>

          <div>
            <div className="row">
              <span className="columns small-8">{t('PERIOD_AUDIT')}</span>
              <span className="columns shrink">
                <span className="row datePickerContainer second padding">
                  <span>{t('FROM')} :</span>
                  <span className="aboutDate">{moment(resultAudit.startDate).format('DD-MM-YYYY')}</span>
                </span>
              </span>
            </div>

            <div className="row">
              <span className="columns small-8">&nbsp;</span>
              <span className="columns shrink">
                <span className="row datePickerContainer secon padding">
                  <span>{t('TO')} :</span>
                  <span className="aboutDateSecond">{moment(resultAudit.endDate).format('DD-MM-YYYY')}</span>
                </span>
              </span>
            </div>
          </div>
          {resultAudit.indiceCompositeHasChangedDuringPeriod
            ? (
              <div className="aboutMessage">
                <span className="columns small-8">
                  <i className="icon-warning"/>
                  {t('CHANGEMENT_INDICE_COMPOSITE')}
                  :
                </span>
                <span
                  className="columns small-4">{resultAudit.indiceCompositeHasChangedDuringPeriod ? t('INDICE_COMPOSITE_CHANGED_DURING_PERIOD') : ''} </span>
              </div>
            ) : (
              <div/>
            )}
        </fieldset>

        <fieldset className="columns auto aboutContainer rightContainer">
          <legend>A propos de l&apos;OPC</legend>
          <div className="row">
            <div className="columns small-16 rightSeparator">
              <div className="row infoContainer">
                <span className="columns  small-14">{t('NATURE')} :</span>
                <span className="columns shrink aboutData">{OpcValues.nature}</span>
              </div>

              <div className="row infoContainer">
                <span className="columns  small-14"> {t('DEVISE')} :</span>
                <span className="columns shrink aboutData">{OpcValues.devise}</span>
              </div>

              <div className="row infoContainer">
                <span className="columns  small-14"> {t('SEUIL_ERROR')} :</span>
                <span className="columns shrink aboutData">{OpcValues.maxTrackingError}</span>
              </div>

              <div className="row infoContainer">
                <span className="columns  small-14">{t('ANALYSE_ECART')} :</span>
                <span className="columns shrink aboutData">{OpcValues.percentageDif}</span>
              </div>

              <div className="row infoContainer">
                <span className="columns  small-14">{t('SEUIL_CORRELATION')} :</span>
                <span className="columns shrink aboutData">{OpcValues.coefCorrelation}</span>
              </div>
            </div>

            <div className="columns small-8">
              <div className="row aboutContainer">
                <span className="columns  small-24 text-center">{t('MONTH_CLOTURE')} :</span>
                <span className="columns small-24 text-center aboutData">
                  {
                    getMatchingMonthOfClosing(OpcValues.closingMonth)
                  }
                </span>
              </div>

              <div className="row aboutContainer">
                <span className="columns  small-24 text-center">{t('AUDIT_FREQUENCE')} :</span>
                <span className="columns small-24 text-center aboutData">
                  {OpcValues.frequence}
                </span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      {resultAudit && resultAudit.missingData && (<div>
        {resultAudit.missingData.some(missing => missing.status === 'REQUESTED' || missing.status === 'PROCESSED') && (
          <div className="blockContainer xsmall">
            <div align={'center'}>
              {t('MISSING_VALUES_REQUESTED')}
            </div>
          </div>
        )
        }
        <div className="blockContainer xsmall">
          <div className="table-opc-audit">
            <table>
              <thead>
              <tr>
                <th> {t('VALORISATIONDATE')}</th>
                <th>{t('LIQUIDATIVE_VALUE')}</th>
                <th>{t('COUPON')}</th>
                <th>{t('VL_COUPON_REINVESTED')}</th>
                <th>{t('PERFORMANCE_OPC')}</th>
                <th>{t('PERF_INDICE_COMPOSITE')}</th>
                <th>{t('CONVERTED_AND_PONDERATED')}</th>
                <th>{t('STANDART_DEVIATION')}</th>
                <th>{t('ANALYSE')}</th>
                <th className="hide-for-print">{t('CALCUL_STANDING')}</th>
              </tr>
              </thead>
              {!resultAudit.resultAudit ? (
                <Fragment>
                  <Spin size="large"/>
                </Fragment>
              ) : (
                <tbody>
                {lastKnowValueOfClosingMonth ? (
                  <Fragment>
                    <tr key="lastKnowValueOfClosingMonth">
                      <td>
                        {lastKnowValueOfClosingMonth.valorisationDate
                          ? transformToValidDate(
                            lastKnowValueOfClosingMonth.valorisationDate,
                          )
                          : t('NO_DATA_BEFORE_PERIOD')}
                      </td>
                      <td>{lastKnowValueOfClosingMonth.liquidatingValue}</td>
                      <td>
                      </td>
                      <td>{lastKnowValueOfClosingMonth.liquidatingValue}</td>
                      <td/>
                      <td/>
                      <td>{lastKnowValueOfClosingMonth.liquidatingValue}</td>
                      <td colSpan="3"/>
                    </tr>
                  </Fragment>
                ) : (
                  <Fragment/>
                )}
                {resultAudit.resultAudit.map(result => (
                    <tr key={result.valorisationDate}>
                      <td>{transformToValidDate(result.valorisationDate)}</td>
                      <td>{result.liquidatingValue}</td>
                      <Fragment>
                        <td>{result.couponPaid}</td>
                        <td>{result.couponReinvested.toFixed(6)}</td>
                        <td>{result.performanceOpc.toFixed(6)}</td>
                        {
                          typeof (result.performanceIndiceComposite) === 'undefined' || result.performanceIndiceComposite === null ? (
                            <Fragment>
                              <td colSpan="4"> {t('MISSING_DATA')}</td>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <td>{result.performanceIndiceComposite.toFixed(6)}</td>
                              <td>{result.CPIC.toFixed(6)}</td>
                              <td>{result.standartDeviation}</td>
                              <td>{result.analyse}</td>
                            </Fragment>
                          )}
                        <td className="hide-for-print" colSpan="1">
                          {
                            !result.missingData || result.missingData.length < 1
                              ? <Fragment/>
                              : <AuditResultModalMissingData missingData={result.missingData}/>
                          }

                        </td>
                      </Fragment>

                    </tr>
                  ),
                )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>)}
    </div>
  );
}

export default withRouter(AuditResult);
