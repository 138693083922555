import React, { useState } from 'react';
import './ConversionPage.scss';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Input, Icon, Button } from 'antd';
import { useTranslate } from 'react-translate';
import ConversionPageBody from './ConversionPageBody/ConversionPageBody';
import DatePickerConversion from './DatePickerConversion/DatePicker';

function ConversionPage() {
  const t = useTranslate('translation');

  const [date, setDate] = useState({
    startdate: moment().startOf('year'),
    enddate: moment(),
  });
  const [inputValue, setInputValue] = useState('');
  const handleChangeInput = e => {
    const valueInput = e.target.value;
    setInputValue(valueInput);
  };
  const propertyBody = {
    search: inputValue,
    startDate: date.startdate,
    endDate: date.enddate,
  };

  return (
    <div className="viewContainer">
      <div className="flex conversion-page-header">
        <h1 className="title">{t('TITLE_CONVERSIONRATE')}</h1>

        <div className="inputContainer text-right">
          <Input
            value={inputValue}
            onChange={e => handleChangeInput(e)}
            className="inputSearch"
            placeholder={t('SEARCH_CONVERSION')}
            prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
            suffix={<Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />}
          />
        </div>
      </div>

      <div className="blockContainer medium">
        <div className="actionElement text-center">
          <DatePickerConversion setDate={setDate} date={date} />
          <Link to="/newConversion">
            <Button className="primary button ripple" type="primary" icon="plus" size="large">
              {t('ADD_CONVERSION')}
            </Button>
          </Link>
        </div>

        <ConversionPageBody propertyBody={propertyBody} />
      </div>
    </div>
  );
}

export default ConversionPage;
