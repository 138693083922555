import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import './ConversionPageNew.scss';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import handleServerResponse from '../../../helpers/reponseManager/reponseHandler';
import getParameterForFetch from '../../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../../helpers/const/API';

function ConversionPageNew(props) {
  const { history } = props;
  const t = useTranslate('translation');
  const [newConversionRate, setNewConversionRate] = useState({});
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const enabled = () => {
    if (newConversionRate.codeIsin && newConversionRate.cotationLocation) {
      setEnableSaveButton(false);
    }
  };

  function handlePropertyChange(e) {
    setNewConversionRate(Object.assign(newConversionRate, { [e.target.name]: e.target.value }));
    enabled();
  }

  function saveNewConversionRate() {
    const params = getParameterForFetch('POST', newConversionRate);
    const url = `${API_SERVEUR}api/conversion/new`;

    fetch(url, params)
      .then(res => res.json())
      .then(res => {
        handleServerResponse(res);
      })
      .then(history.push('/conversion'));
  }

  return (
    <div className="viewContainer">
      <h1 className="title">{t('NEW_CONVERSION_RATE')}</h1>

      <div className="row blockContainer small">
        <div className="columns small-10 conversionContainer">
          <div>
            <div>
              <label>{t('CODE_ISIN')}</label>
              <input type="text" name="codeIsin" onChange={handlePropertyChange} required />
            </div>

            <div>
              <label>{t('LABEL')}</label>
              <input type="text" name="label" onChange={handlePropertyChange} />
            </div>

            <div>
              <label>{t('COTATION_LOCATION')}</label>
              <input
                name="cotationLocation"
                onChange={handlePropertyChange}
                type="number"
                required
                min="0"
              />
            </div>

            <div>
              <label>{t('DEVISE')}</label>
              <input type="text" name="devise" onChange={handlePropertyChange} />
            </div>

            <div>
              <label>{t('DEVISE2')}</label>
              <input type="text" name="devise2" onChange={handlePropertyChange} />
            </div>
          </div>

          <Link className="buttonContainer">
            <Button
              className="outlineSecondary button ripple right-8"
              onClick={() => history.push('/conversion')}
            >
              {t('CANCEL')}
            </Button>

            <Button
              className="secondary button ripple"
              type="secondary"
              icon="plus"
              onClick={saveNewConversionRate}
              disabled={enableSaveButton}
            >
              {t('ADD')}
            </Button>
          </Link>
        </div>

        <div className="columns auto conversionImg">&nbsp;</div>
      </div>
    </div>
  );
}

export default ConversionPageNew;
