import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-translate';
import { Link } from 'react-router-dom';

import getParameterForFetch from '../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../helpers/const/API';
import handleServerResponse from '../../helpers/reponseManager/reponseHandler';

function UserListManager() {
  const t = useTranslate('translation');
  const [allUsers, setAllUser] = useState([]);

  function getAllUser() {
    const parametersAllUsers = getParameterForFetch('GET');

    const urlAllUsers = 'api/users/allUsers';
    const fetchAllUser = `${API_SERVEUR}${urlAllUsers}`;
    fetch(fetchAllUser, parametersAllUsers)
      .then(res => res.json())
      .then(res => setAllUser(res));
  }

  useEffect(() => {
    getAllUser();
  }, []);

  const userStatus = t('USER');
  const adminStatus = t('ADMIN');

  const deleteUser = _id => {
    const urlDeleteUser = `${API_SERVEUR}api/users/deleteUser`;
    const bodyDelete = { _id };
    const parameters = getParameterForFetch('POST', bodyDelete);
    fetch(urlDeleteUser, parameters)
      .then(res => res.json())
      .then(res => handleServerResponse(res))
      .then(() => getAllUser());
  };

  return (
    <div className="viewContainer">
      <h1 className="title">{t('USERS_MANAGEMENT')}</h1>
      <div className="blockContainer medium">
        <div className="add-button-container">
          <button type="button" className="ant-btn secondary button">
            <Link to="/admin/register">{t('ADD_USER')}</Link>
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <td>{t('EMAIL')}</td>
              <td>{t('ROLE')}</td>
              <td>{t('ACTION')}</td>
            </tr>
          </thead>
          <tbody>
            {allUsers.map(user => (
              <tr key={user._id}>
                <td>{user.email}</td>
                <td>
                  {!user.isAdmin && userStatus}
                  {user.isAdmin && adminStatus}
                </td>
                <td>
                  <button
                    type="button"
                    className="ant-btn secondary button"
                    onClick={() => deleteUser(user._id)}
                  >
                    {t('DELETE')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default UserListManager;
