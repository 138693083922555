import React, { useState } from 'react';
import './ModalForgetPassword.scss';
import { Modal, Button } from 'antd';
import ForgetPassword from '../../../assets/images/png/forget_password.png';

export default function ModalForgetPassword() {
  const [visible, setVisible] = useState(false);

  return (
    <div className="buttonForgetPass">
      <Button onClick={() => setVisible(true)}>
        Mot de passe oublié?
      </Button>
      <Modal
className="passwordContainer"
        title="Mot de passe perdu?"
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <img src={ForgetPassword} alt="" />
        <p>En cas de mot de passe perdu, veuillez contacter l’administrateur de cette plateforme</p>
      </Modal>
    </div>
  );
}
