import React, {Fragment} from 'react';
import { useTranslate } from 'react-translate';
import GeneralOpcTableRow from './generalOpcTableRow/generalOpcTableRow';

const GeneralOpcTable = props => {
  const { allOpc } = props;
  const t = useTranslate('translation');
  return (
    <div className="tableContainer large">
      <table>
        <thead>
          <tr>
            <th>{t('CODE_ISIN')}</th>
            <th className="large-size">{t('LABEL_OPC')}</th>
            <th>{t('OPCVM')}</th>
            <th className="medium-size">Nature</th>
            <th className="small-size">Devise</th>
            <th>Type de Cours</th>
            <th>Date de cloture</th>
            <th className="small-size">Actif</th>
            <th className="small-size">Voir</th>
          </tr>
        </thead>
        <tbody>
          {allOpc.length ? 
            allOpc.map(opc => (
            <GeneralOpcTableRow opc={opc} key={opc._id} />
          ))
          :
          <Fragment />
          }
        </tbody>
      </table>
    </div>
  );
};

export default GeneralOpcTable;
