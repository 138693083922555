export function isUserLogged() {
  const token = localStorage.getItem('token');
  return token !== null && token !== undefined;
}

export function connectUser(user, token) {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
}

export function disconnectUser() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
}
export function isUserAdmin() {
  let user;
  if (localStorage.getItem('user')) {
    user = JSON.parse(localStorage.getItem('user'));
  }
  if (user && user.isAdmin === true) {
    return true;
  }
  return false;
}
