import React, { useEffect, useState } from 'react';

export const AuthContext = React.createContext();

export default function AuthContextHook({ children }) {
  const prevUser = localStorage.getItem('user');
  const prevToken = localStorage.getItem('token');

  const [token, setToken] = useState(prevToken);
  const [user, setUser] = useState(prevUser);

  useEffect(() => {
    if (!token) {
      localStorage.removeItem('token');
    } else {
      localStorage.setItem('token', token);
    }

    if (!user) {
      localStorage.removeItem('user');
    } else {
      localStorage.setItem('user', user);
    }
  });

  function isUserLogged() {
    return token !== null || token !== undefined;
  }

  function connectUser() {
    setToken(JSON.stringify(token));
    setUser(JSON.stringify(user));
  }

  function disconnectUser() {
    setToken();
    setUser();
  }

  const defaultContext = {
    token,
    user,
    connectUser,
    disconnectUser,
    isUserLogged,
  };
  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>;
}
