import React, { useState, useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import SideNavPage from './SideNav/SideNav';
import ConnexionPage from './Page/ConnexionPage/ConnexionPage';
import ProtectedRoute from './component/ProtectedRoute';

import routes from './helpers/const/routes';
import routesAdmin from './helpers/const/routesAdmin';
import Header from './header/Header';
import { isUserLogged, disconnectUser, isUserAdmin } from './helpers/function/authentication';
import hiddenRoutes from './helpers/const/hiddenRoute';
import HiddenRoute from './component/HiddenRoute';
import RouteAdmin from './component/RouteAdmin';

const history = createBrowserHistory();

require('dotenv').config();

function App() {
  const [userIsLogged, setuserIsLogged] = useState(true);
  const [userIsAdmin, setuserIsAdmin] = useState(false);

  function actionLogged() {
    setuserIsLogged(isUserLogged());
    setuserIsAdmin(isUserAdmin());
  }
  useEffect(() => {
    setuserIsLogged(isUserLogged());
    setuserIsAdmin(isUserAdmin());
  }, []);
  return (
    <div className={`App ${!isUserLogged() ? 'login-page' : ''}`}>
      <BrowserRouter history={history}>
        <div>
          {userIsLogged ? (
            <div className="header">
              <Header
                disconnectUser={disconnectUser}
                history={history}
                actionLogged={actionLogged}
              />
              <SideNavPage navigation={routes} userIsAdmin={userIsAdmin} />
            </div>
          ) : (
            <div />
          )}
          <Switch>
            {hiddenRoutes.map(hiddenRoute => (
              <HiddenRoute
                path={hiddenRoute.path}
                component={hiddenRoute.component}
                key={hiddenRoute.id}
                userIsLogged={userIsLogged}
                history={history}
              />
            ))}
            {routesAdmin.map(routeAdmin => (
              <RouteAdmin
                path={routeAdmin.path}
                component={routeAdmin.component}
                key={routeAdmin.id}
                isUserAdmin={isUserAdmin}
                history={history}
              />
            ))}
            {routes.map(route => (
              <ProtectedRoute
                path={route.path}
                component={route.component}
                key={route.id}
                userIsLogged={userIsLogged}
                history={history}
              />
            ))}
            <Route
              path="/"
              exact
              render={() => {
                if (!isUserLogged()) {
                  return <ConnexionPage history={history} actionLogged={actionLogged} />;
                } else {
                  return <Redirect to="/accueil" exact />;
                }
              }}
            />
            <Redirect to="/" />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
