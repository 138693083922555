import React, { useState, useEffect } from 'react';

import { useTranslate } from 'react-translate';
import moment from 'moment';
import './homepage.scss';
import GeneralOpcTable from '../../containers/GeneralOpcTable';
import { API_SERVEUR } from '../../helpers/const/API';
import getParameterForFetch from '../../helpers/function/paramFetch';

function HomePage() {
  const t = useTranslate('translation');
  const [selectBody, setBody] = useState('toDo');
  const [numberOfOpc, setNumberOfOpc] = useState();

  const [Opc, setOpc] = useState([]);

  const getOpcByStatus = () => {
    const url = `${API_SERVEUR}api/opc/retrieveAllOpc`;
    const parameters = getParameterForFetch('POST');
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setOpc(res));
  };
  const getNumberOfOpc = () => {
    const url = `${API_SERVEUR}api/opc/numberOfOPC`;
    const parameters = getParameterForFetch('POST');
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setNumberOfOpc(res));
  };
  const [numberOfIndice, setNumberOfIndice] = useState();
  const getNumberOfIndice = () => {
    const url = `${API_SERVEUR}api/indice/totalOfIndice`;
    const parameters = getParameterForFetch('POST');
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setNumberOfIndice(res));
  };

  const [NumberOfValorisedIndice, setNumberOfValorisedIndice] = useState();
  const getNumberOfValorisationIndice = () => {
    const url = `${API_SERVEUR}api/valorisationIndice/getNumberOfValorisationIndice`;
    const parameters = getParameterForFetch('POST');
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setNumberOfValorisedIndice(res.numberOfValorisationIndice));
  };
  const [ongoingAudit, setOngoingAudit] = useState([]);
  const getOnGoingAuditResult = () => {
    const url = `${API_SERVEUR}api/auditResult/getOngoingAudit`;
    const parameters = getParameterForFetch('POST');
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setOngoingAudit(res));
  };
  const [recentAudit, setRecentAudit] = useState([]);
  const getRecentAudit = () => {
    const body = {
      minDate: moment(new Date()).subtract('30', 'day'),
    };
    const url = `${API_SERVEUR}api/auditResult/getRecentAudit`;
    const parameters = getParameterForFetch('POST', body);
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setRecentAudit(res));
  };
  useEffect(() => {
    getNumberOfOpc();
    getNumberOfIndice();
    getNumberOfValorisationIndice();
    getOpcByStatus();
    getOnGoingAuditResult();
    getRecentAudit();
  }, []);

  function handleBodyChange(e) {
    setBody(e.target.value);
  }

  return (
    <div className="home">
      <h1 className="title">{t('TABLEAU_DE_BORD')}</h1>

      <div className="home-indicator">
        <div className="home-indicator-item">
          <div className="home-indicator-item-numberContainer">
            <h2 className="home-indicator-item-number">{numberOfOpc ? numberOfOpc.opc : 0}</h2>
            <p>OPC</p>
          </div>
        </div>

        <div className="home-indicator-item">
          <div className="home-indicator-item-numberContainer">
            <h2 className="home-indicator-item-number">
              {numberOfIndice ? numberOfIndice.indice : 0}
            </h2>
            <p>indices</p>
          </div>
        </div>

        <div className="home-indicator-item">
          <div className="home-indicator-item-numberContainer">
            <h2 className="home-indicator-item-number">
              {NumberOfValorisedIndice || 0}
            </h2>
            <p>{t('INDICE_VALORISATION')}</p>
          </div>
        </div>
      </div>

      <div className="blockContainer large">
        <div className="home-menu">
          <button
            className={` home-menu-item button ${selectBody === 'toDo' ? 'selected' : ''}`}
            value="toDo"
            onClick={handleBodyChange}
            type="button"
          >
            {t('TODO')}
          </button>
          <button
            className={` home-menu-item button ${selectBody === 'inProgress' ? 'selected' : ''}`}
            value="inProgress"
            onClick={handleBodyChange}
            type="button"
          >
            {t('IN_PROGRESS')} ({ongoingAudit.length})
          </button>
          <button
            className={` home-menu-item button ${selectBody === 'recent' ? 'selected' : ''}`}
            value="recent"
            onClick={handleBodyChange}
            type="button"
          >
            {t('RECENT')} ({recentAudit.length})
          </button>
        </div>
        {selectBody === 'inProgress' ? <GeneralOpcTable allOpc={ongoingAudit} /> : <div />}
        {selectBody === 'toDo' ? <GeneralOpcTable allOpc={Opc} /> : <div />}
        {selectBody === 'recent' ? <GeneralOpcTable allOpc={recentAudit} /> : <div />}
      </div>
    </div>
  );
}

export default HomePage;
