/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import getParameterForFetch from '../../helpers/function/paramFetch';
import handleResponse from '../../helpers/reponseManager/reponseHandler';
import { connectUser, disconnectUser } from '../../helpers/function/authentication';
import { API_SERVEUR } from '../../helpers/const/API';
import './ConnexionPage.scss';
import ModalForgetPassword from './ModalForgetPassword/ModalForgetPassword';

function ConnexionPage(props) {
  const { history, actionLogged } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function signIn(user, token) {
    if (!token) {
      disconnectUser();
      return;
    }
    connectUser(user, token);

    actionLogged();
    history.push('/accueil');
  }
  const LogIn = e => {
    e.preventDefault();
    const parameters = getParameterForFetch('POST', { email, password });
    const url = `${API_SERVEUR}api/users/login`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(user => {
        handleResponse(user);
        signIn(user, user.token);
      })
      .catch(handleResponse);
  };

  return (
    <div className="login">
      <div>
        <div className="login-form">
          <div>
            <h1>Bienvenue</h1>
          </div>

          <form
            onSubmit={e => {
              LogIn(e);
            }}
          >
            <div className="login-id">
              <label>
                Identifiant
                <input type="text" name="email" onChange={e => setEmail(e.target.value)} />
              </label>
            </div>
            <div className="">
              <label className="row">
                <div className="columns small-12 ">Mot de passe</div>
                <div className="columns small-12 text-right login-forgetPassword">
                  <ModalForgetPassword />
                </div>

                <input
                  type="password"
                  name="password"
                  onChange={e => setPassword(e.target.value)}
                />
              </label>
            </div>
            <div className="buttonContainer">
              <button
                className="primary button ripple"
                type="submit"
                onClick={e => {
                  LogIn(e);
                }}
              >
                Connexion
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ConnexionPage;
