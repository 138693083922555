import { CSVLink } from 'react-csv';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslate } from 'react-translate';
import moment from 'moment';
import './AuditResultCsvExport.scss';

const AuditResultCsvExport = props => {
  const { dataToExport, filename } = props;
  const t = useTranslate('translation');
  const headers = [
    { label: 'Date de valorisation', key: 'valorisationDate' },
    { label: 'Valeur Liquidative', key: 'liquidatingValue' },
    { label: 'Coupon versé', key: 'couponPaid' },
    { label: 'Performance Opc', key: 'performanceOpc' },
    { label: 'Valeur liquidative des coupons réinvestis', key: 'couponReinvested' },
    { label: "Performance de l'indice composite", key: 'performanceIndiceComposite' },
    { label: 'Cours converti et pondéré IC', key: 'CPIC' },
    { label: 'Ecart de performance', key: 'standartDeviation' },
    { label: 'Analyse', key: 'analyse' },
  ];

  const [transformedDataToExport, setTransformedDataToExport] = useState([]);

  const transformDataToExport = useCallback(() => {
    if (dataToExport[0]) {
      const transformedData = [];
      dataToExport.forEach(data => {
        let newData = {};
        newData = Object.assign(newData, data);
        newData.valorisationDate = moment(data.valorisationDate).format('DD-MM-YYYY');
        newData.liquidatingValue = convertNumberToFrenchString(data.liquidatingValue);
        newData.couponPaid = convertNumberToFrenchString(data.couponPaid);
        newData.performanceOpc = convertNumberToFrenchString(data.performanceOpc);
        newData.couponReinvested = convertNumberToFrenchString(newData.couponReinvested);
        newData.performanceIndiceComposite = convertNumberToFrenchString(newData.performanceIndiceComposite);
        newData.CPIC = convertNumberToFrenchString(newData.CPIC);
        newData.standartDeviation = convertNumberToFrenchString(newData.standartDeviation);
        transformedData.push(newData);
      });
      return setTransformedDataToExport(transformedData);
    }
  }, [dataToExport]);

  useEffect(() => {
    transformDataToExport();
  }, [transformDataToExport]);

  function convertNumberToFrenchString(number) {
    return number || number === 0 ? number.toLocaleString('fr-FR', {minimumFractionDigits: 6, maximumFractionDigits: 6}) : "";
  }

  return (
    <CSVLink
      className="button primary"
      data={transformedDataToExport}
      headers={headers}
      filename={filename}
      separator={";"}
    >
      <i className="icon-download margin-right-8" />
      {t('DOWNLOAD_AUDIT')}
    </CSVLink>
  );
};
AuditResultCsvExport.defaultProps = {
  dataToExport: [
    {
      valorisation: 'Coucou',
    },
    {
      valorisation: 'Coucou',
    },
  ],
};
export default AuditResultCsvExport;
