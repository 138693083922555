import ConversionPageNew from '../../Page/ConversionPage/ConversionPageNew/ConversionPageNew';
import ReferentielPageNew from '../../Page/ReferentielPage/ReferentielPageNew/ReferentielPageNew';
import OpcManager from '../../Page/OPCPage/OpcManager/OpcManager';
import OpcCreation from '../../Page/OPCPage/OpcCreation/OpcCreation';
import AuditValidation from '../../Page/Audit/AuditValidation/AuditValidation';
import AuditResult from '../../Page/Audit/AuditResult/AuditResult';

export const HiddenRoutes = [
  {
    id: 5,
    path: '/newConversion',
    component: ConversionPageNew,
  },
  {
    id: 6,
    path: '/newIndice',
    component: ReferentielPageNew,
  },
  {
    id: 7,
    path: '/gestionOpc',
    component: OpcManager,
  },
  {
    id: 8,
    path: '/creationOpc',
    component: OpcCreation,
  },
  {
    id: 9,
    path: '/auditOpc',
    component: AuditValidation,
  },
  {
    id: 10,
    path: '/resultAudit',
    component: AuditResult,
  },
];

export default HiddenRoutes;
