import React, { useState } from 'react';

import { useTranslate } from 'react-translate';
import { Link, withRouter } from 'react-router-dom';
import OpcCaracteristique from '../OpcCaracteristique/OpcCaracteristique';
import OpcIndiceComposite from '../OpcIndiceComposite/OpcIndiceComposite';
import OpcAudit from '../OpcAudit/OpcAudit';
import './OpcManager.scss';

function OpcManager(props) {
  const t = useTranslate('translation');
  const { history } = props;
  const [OpcValues, setOpcValue] = useState(
    history.location.state.opc ? history.location.state.opc : {},
  );
  const url = 'api/opc/updateOpc';
  return (
    <div className="viewContainer">
      <div>
        <h3 className="title">
          {t('OPC_MANAGER_TITLE')}:{OpcValues ? OpcValues.codeIsin : ''}-
          {OpcValues ? OpcValues.label : ''}
        </h3>
      </div>
      <div>
        <Link key="54966" exact="true" to="/opc">
          <button className="primary button ripple" type="button">
            <i className="icon-arrow-left2 margin-8" />
            {t('BACK_TO_OPC_LIST')}{' '}
          </button>{' '}
        </Link>
      </div>
      <OpcCaracteristique
        OpcValues={OpcValues ? OpcValues._id : null}
        url={url}
        setOpcValue={setOpcValue}
      />

      <OpcIndiceComposite OpcValues={OpcValues} />

      {OpcValues._id && <OpcAudit OpcValues={OpcValues} />}
    </div>
  );
}

export default withRouter(OpcManager);
