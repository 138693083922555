import { Modal, Button } from 'antd';
import React, { useState, Fragment } from 'react';
import { useTranslate } from 'react-translate';
import moment from 'moment';
// import getParameterForFetch from '../../../../helpers/function/paramFetch';

export default function AuditResultModalMissingData(props) {
  const { missingData } = props;
  const [visible, setVisible] = useState(false);
  const t = useTranslate('translation');

  return (
    <div>
      <Button className="roundButton roundSecondary" onClick={() => setVisible(true)}>
        <i className="icon-eye" />
      </Button>
      <Modal
        title={t('MISSING_DATA')}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
        <Fragment>
          {missingData ? (
            <table>
              <thead>
                <tr>
                  <th>{t('VALORISATIONDATE')}</th>
                  <th>{t('CODE_ISIN')}</th>
                  <th>{t('COTATION_LOCATION')}</th>
                  <th>{t('DESCRIPTION')}</th>
                </tr>
              </thead>
              <tbody>
                {missingData.map(data => (
                  <tr key={data.codeIsin + moment(data.valorisationDate).format('DD-MM-YYYY')}>
                    <td>{moment(data.valorisationDate).format('DD-MM-YYYY')}</td>
                    <td>{data.codeIsin}</td>
                    <td>{data.cotationLocation}</td>
                    <td>{data.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{t('NO_VALUES_FOUND')}</p>
          )}
        </Fragment>
      </Modal>
    </div>
  );
}
