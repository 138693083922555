/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslate } from 'react-translate';
import './header.scss';
import ACANexiaLogo from '../assets/images/svg/logo_aca.svg';

function Header(props) {
  const { disconnectUser, actionLogged } = props;
  const t = useTranslate('translation');

  function disconnection() {
    disconnectUser();
    actionLogged();
  }

  return (
    <div className="main-header">
      <div className="headerMenu-logo" key="0">
        <img src={ACANexiaLogo} alt="" />
      </div>
      <div className="header-logout-link hide-for-print">
        <Link key="546" exact="true" to="/">
          <Button className="secondary button ripple" onClick={() => disconnection()}>
            {t('DISCONNECT')}
          </Button>
        </Link>
      </div>
    </div>
  );
}
Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default Header;
