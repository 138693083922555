import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const COUPON_PAID_COLUMN_NAME = 'couponPaid';

export default function TablePrevisualisation(props) {
  const { rows, valuesHeader, fileName, couponColumnDisabled, headerIgnored } = props;
  function isColumnDisplayed(headerValue) {
    return !(couponColumnDisabled && headerValue.hiddenName === COUPON_PAID_COLUMN_NAME);
  }

  return (
    <div className="file-item">
      <Paper>
        <div className="file-name">
          <b>Fichier</b> : {fileName}
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              {valuesHeader.values.filter(isColumnDisplayed).map((headerValue, index) => (
                <TableCell key={index}>{headerValue.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .filter((row, rowIndex) => (rowIndex !== 0 || !headerIgnored))
              .slice(0,3)
              .map((row, rowIndex) => {
              return (
                <TableRow key={rowIndex}>
                  {valuesHeader.values.filter(isColumnDisplayed).map((headerValue, index) => {
                    return <TableCell key={index}>{row[headerValue.column - 1]}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
