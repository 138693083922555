const translation = {
  TODUPLICATE: '',
  NO_INDICE_COMPOSITE_BEFORE_PERIOD: "Pas d'indice composite avant la période choisie",
  PREVISUALISATION: 'Prévisualiser',
  PREVISUALISATION_DATA: 'Prévisualisation des données à importer',
  NO_DATA_BEFORE_PERIOD: "Il manque une valeur référence",
  CHANGEMENT_INDICE_COMPOSITE: "Changement d'indice composite",
  INDICE_COMPOSITE_CHANGED_DURING_PERIOD: "L'indice composite a changé durant la période d'audit",
  NO_VALUES_FOUND: 'Aucune valeur trouvée',
  PERIOD_HAVE_NO_LIQUIDATION_VALUE: "Il n'y a aucune valeur liquidative pour la période",
  PLEASE_FILL_THE_OPC: "Veuillez remplir les champs nécessaire à l'enregistrement de l'OPC",
  PURGE_VL : "Vous pouvez purger les Valeurs Liquidatives",
  OPC_IS_READY_TO_SAVE: "Vous pouvez sauvegarder l'OPC",
  FROM: 'De',
  BETWEEN: 'Entre',
  AND: 'et',
  PERIOD_AUDIT: 'Période',
  TO: 'à',
  GET_MISSING_DATA: 'Récupérer les données manquantes',
  YES: 'Oui',
  NO: 'Non',
  BACK_TO_OPC_LIST: 'Retour à la liste des OPC',
  DOWNLOAD_AUDIT: "Télécharger l'audit",
  NEW_CONVERSION_RATE: 'Nouveau taux de conversion',
  ADD_NEW_INDICE: 'Ajouter un indice',
  EDIT_INDICE: 'Editer un indice',
  ADD_NEW_INDICE_COMPOSITE: 'Ajouter un nouvel Indice Composite',
  SHOW_AUDIT: "Voir l'audit",
  INCOMPLETE: 'Audit incomplet',
  COMPARED_VALUES: 'Valeurs comparées',
  DONE_THE: 'Effectué le',
  AND_THE: 'et le',
  CONTROLE_BETWEEN: 'Contrôle effectué entre le',
  MISSING_VALUES_FOR_DATE: 'Les données manquantes pour la date',
  MISSING_VALUES_REQUESTED : 'Récupération des données en cours. Le temps nécessaire est fonction du nombre de valeurs demandées.' +
    ' Recharger la page pour voir l\'audit.',
  SHOW_MISSING_DATA: 'Voir les données manquantes',
  MISSING_DATA: 'Données manquantes',
  VL_PURGE: 'Purge des valeurs liquidatives',
  MISSING_DATA_REQUESTED : 'Données requêtées',
  MISSING_DATA_PROCESSED : 'Données en cours de traitement',
  USER_CREATION_TITLE: "Création d'un utilisateur",
  NAME: 'Nom',
  MAIL: 'Mail',
  PASSWORD: 'Mot de passe',
  CONFIRM_PASSWORD: 'Confirmation du mot de passe',
  USERS_MANAGEMENT: 'Gestion des utilisateurs',
  CHOOSE_FILE: 'Choix des fichiers',
  NO_VALUES_TO_DISPLAY: 'Aucune valeurs à afficher',
  DROP_ZONE_TEXT: 'Choisissez des fichiers .cvs à importer ou déposez-les ici',
  ADD_TO_COMPOSITE: "Ajouter un indice à l'indice composite",
  TOGGLE_LIQUIDATIVE_VALUES: 'Afficher les valeurs liquidatives à comparer',
  DATE_REALISATION_AUDIT: "Date de réalisation de l'audit",
  DATE_START_AUDIT: "Début de la période d'audit",
  DATE_END_AUDIT: "Fin de la période d'audit",
  ANNULER: 'Annuler',
  DO_AN_AUDIT: 'Réaliser un audit',
  NO_AUDIT_DONE_YET: "Aucun audit n'a été réalisé pour cet OPC à ce jour",
  OPC_AUDIT_TITLE: 'Historique des audits de cet OPC',
  OPC_MODAL_TITLE: "Quels indices souhaitez-vous ajouter à l'indice composite?",
  VALIDE_FROM: 'Validité à partir du',
  OPC_TITLE_INDICE: 'Indice(s) composite(s) ',
  TITLE_CONVERSIONRATE: 'Tableau de valorisation des taux de conversion ',
  SEARCH: 'Rechecher une devise',
  INDICE: 'Indice',
  ADD_CONVERSION: 'Ajouter un taux de conversion',
  ADD_INDICE: 'Ajouter un indice',
  CODE_ISIN: 'Code ISIN',
  LABEL: 'Libellé',
  OPCVM: 'Code OPCVM',
  LABEL_OPC: "Libellé de l'OPC",
  DELETION_WARNING: "Êtes-vous sûr de vouloir supprimer cette valeur ?",
  INDICE_VALORISATION: "Valorisation d'indices",
  COTATION_LOCATION: 'Code place de cotation',
  DEVISE1: 'Devise principal',
  DEVISE2: 'Devise secondaire',
  DEVISE: 'Devise',
  VOIR: 'Voir',
  EDIT: 'Editer',
  ADD_USER: 'Nouvel utilisateur',
  EMAIL: 'Email',
  ROLE: 'Rôle',
  ACTION: 'Action',
  DELETE: 'Supprimer',
  CONFIRM: 'Valider',
  DISCONNECT: 'Déconnexion',
  DISABLED: 'Omettre',
  VALORISATIONDATE: 'Date de valorisation',
  DESCRIPTION: 'Description',
  FIRSTSTOCKMARKET: 'Premier cours',
  LOWESTSTOCKMARKET: 'Cours le plus bas',
  HIGHESTSTOCKMARKET: 'Cours le plus haut',
  LASTSTOCKMARKET: 'Dernier cours',
  ESTIMATIONSTOCKMARKET: 'Cours estimé',
  ADD: 'Ajouter',
  CANCEL: 'Annuler',
  OPC: 'OPC',
  TABLEAU_DE_BORD: 'Tableau de bord',
  TODO: 'Audit à réaliser',
  IN_PROGRESS: 'Audit en cours',
  RECENT: 'Audit récent',
  TITLE_REFERENTIEL: 'Réferentiel des indices',
  RECUPERATION_STATE: 'Etat de la récuperation',
  OPC_USING: "Nombre d'OPC l'utilisant",
  SEARCH_INDICE: 'Rechercher un indice',
  SEARCH_CONVERSION: 'Rechercher par code ISIN ou libellé',
  NATURE: 'Nature',
  STOCK_MARKET_TYPE: 'Type de cours',
  CLOTURE_DATE: 'Date de clôture',
  ACTIF: 'Actif',
  SEARCH_OPC: 'Rechercher un OPC',
  ADD_OPC: 'Ajouter un OPC',
  TITLE_OPC: 'Liste des OPC',
  OPC_MANAGER_TITLE: "Fiche de l'Opc",
  OPC_CARACTERISTIQUE: "Caractéristiques de l'Opc",
  FISCAL_TYPE: 'Type fiscal',
  FIRST: 'Premier',
  LAST: 'Dernier',
  ANALYSE_ECART: "Seuil d'analyse des écarts",
  SEUIL_CORRELATION: 'Seuil de corrélation',
  SEUIL_ERROR: "Seuil d'erreur",
  MONTH_CLOTURE: 'Mois de clotûre annuelle',
  AUDIT_FREQUENCE: "Fréquence d'audit",
  TRIMESTRE: 'Trimestriel',
  SEMESTRE: 'Semestriel',
  ANNUEL: 'Annuel',
  SOURCE: "Source de l'indice",
  WEIGHT: 'Poids',
  SAVE: 'Enregistrer',
  PURGE: 'Purger des valeurs liquidatives',
  CONFIRM_PURGE: 'Purger',
  PERIOD_TO_PURGE: 'Quelle période souhaitez-vous purger ? \n Les valeurs liquidatives de cet OPC seront supprimées entre les dates suivantes:',
  CAPITALISE: 'Capitalisé',
  CLASSIC: 'Classique',
  INDICIEL: 'Indiciel',
  FORMULE: 'Formule',
  DETACHE: 'Détaché',
  INTERNE: 'Interne',
  EXTERNE: 'Externe',
  USER: 'Utilisateur',
  ADMIN: 'Administrateur',
  USER_IS_ADMIN: "L'utilisateur est un administrateur",
  AUDIT_OPC: "Audit de l'OPC",
  PREPARATION_AUDIT: "Préparation de l'audit",
  LIQUIDATIVE_VALUE: 'Valeurs liquidatives',
  COUPON: 'Coupon versé',
  NO_VALUE_TO_DISPLAY: 'Aucune valeur pour la période sélectionner',
  PERFORMANCE_OPC: 'Performance OPC (en %)',
  VL_COUPON_REINVESTED: 'Valeur liquidative coupons réinvestis',
  PERF_INDICE_COMPOSITE: "Performance de l'indice composite",
  CONVERTED_AND_PONDERATED: 'Cours converti et pondéré IC',
  STANDART_DEVIATION: 'Ecart de performance(en %)',
  ANALYSE: 'Analyse',
  CALCUL_STANDING: 'Etat des calculs',
  START_AUDIT: "Commencer l'audit",
  VALUE_BETWEEN: 'Valeurs comprises entre le ',
  DOWNLOAD_SIX_FILES: 'Demander les données manquantes'
};

export default translation;
