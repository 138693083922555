import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslate } from 'react-translate';
import { DatePicker, Button } from 'antd';
import OpcTableComposite from './OpcTableComposite/OpcTableComposite';
import OpcModalIndice from '../OpcModalIndice/OpcModalIndice';
import { API_SERVEUR } from '../../../helpers/const/API';
import getParameterForFetch from '../../../helpers/function/paramFetch';
import './OpcIndiceComposite.scss';
import OpcHistoryComposite from './OpcHistoryComposite/OpcHistoryComposite';

function OpcIndiceComposite(props) {
  const { OpcValues, selectedIndiceToModify } = props;
  const t = useTranslate('translation');
  const [indicesComposite, setIndiceComposite] = useState([]);
  const [dateIndiceComposite, setDateIndiceComposite] = useState({});
  const [isModalIndiceOpen, setModalIndiceOpen] = useState(false);
  const [indicesForComposites, setIndicesForComposite] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  function createNewIndiceCompositeDate(date) {
    setDateIndiceComposite({ ...dateIndiceComposite, firstApplicationDate: date });
  }

  const handleIndiceDelete = indice => {
    const copyOfIndiceComposite = indicesComposite;
    setIndiceComposite(
      copyOfIndiceComposite.filter(elementOfIndice => elementOfIndice._id !== indice._id),
    );
  };

  useEffect(() => {
    const parameters = getParameterForFetch('POST');
    const url = `${API_SERVEUR}api/indice/retrieveIndice`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setIndicesForComposite(res));
  }, []);

  function setWeightForIndice(e, indice) {
    indice.weight = Number(e.target.value);
  }

  const [toggleCreationNewIndiceComposite, settoggleCreationNewIndiceComposite] = useState(false);

  return (
    <div className="blockContainer">
      <h4 className="subtitle">{t('OPC_TITLE_INDICE')}</h4>
      <div className="indiceHeader">
        <OpcHistoryComposite
          opc={OpcValues}
          setForceUpdate={setForceUpdate}
          forceUpdate={forceUpdate}
        />
      </div>

      <div className="buttonContainer">
        <Button
          className="outlineSecondary button ripple"
          type="secondary"
          icon="plus"
          size="large"
          onClick={() => settoggleCreationNewIndiceComposite(!toggleCreationNewIndiceComposite)}
        >
          {t('ADD_NEW_INDICE_COMPOSITE')}
        </Button>
      </div>

      {}

      {toggleCreationNewIndiceComposite ? (
        <div>
          <fieldset className="newComposite">
            <legend>Nouvel indice composite</legend>

            <div className="newComposite-content">
              <div className="datePickerContainer">
                <p className="margin-bottom-0">{t('VALIDE_FROM')}</p>
                <DatePicker
                  className="firstApplicationDate"
                  format="DD-MM-YYYY"
                  placeholder="Choisir une date"
                  onChange={e => createNewIndiceCompositeDate(e)}
                />
              </div>

              <div>
                <Button
                  className="button secondary"
                  type="secondary"
                  icon="plus"
                  onClick={() => setModalIndiceOpen(!isModalIndiceOpen)}
                >
                  {t('ADD_TO_COMPOSITE')}
                </Button>
              </div>
            </div>

            {indicesComposite.length ? (
              <OpcTableComposite
                settoggleCreationNewIndiceComposite={settoggleCreationNewIndiceComposite}
                OpcValues={OpcValues}
                setForceUpdate={setForceUpdate}
                selectedIndiceToModify={selectedIndiceToModify}
                indicesComposite={indicesComposite}
                setWeightForIndice={setWeightForIndice}
                OpcID={OpcValues}
                firstApplicationDate={dateIndiceComposite.firstApplicationDate}
                handleIndiceDelete={handleIndiceDelete}
                setIndiceComposite={setIndiceComposite}
              />
            ) : (
              <div />
            )}
          </fieldset>
        </div>
      ) : (
        <div />
      )}
      <OpcModalIndice
        isModalIndiceOpen={isModalIndiceOpen}
        setModalIndiceOpen={setModalIndiceOpen}
        indicesForComposites={indicesForComposites}
        setIndiceComposite={setIndiceComposite}
      />
    </div>
  );
}

export default withRouter(OpcIndiceComposite);
