import React, { useState, useEffect, Fragment } from 'react';
import './ConversionPageBody.scss';
import { useTranslate } from 'react-translate';
import getParameterForFetch from '../../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../../helpers/const/API';
import TableOfSharedReference from '../../../component/shared/TableOfSharedReference';

function ConversionPageBody(props) {
  const { propertyBody } = props;
  const t = useTranslate('translation');
  const urlFetch = 'api/conversion/searchConversionRate';
  const urlDeletionConversionRate = `${API_SERVEUR}api/valorisationConversionRate/deleteValorisationConversionRate/`;


  const [valorisationConversionRate, setValorisationConversionRate] = useState([]);

  const [dataConversionRate, setdataConversionRate] = useState([]);

  function getValorisationConversionRateForConversionRate(conversionRate) {
    const urlValorisationConversionRate = `${API_SERVEUR}api/valorisationConversionRate/getValorisationConversionRateByNameOrCodeIsin`;
    const body = { label: conversionRate.label, codeIsin: conversionRate.codeIsin, skip, limit };
    const queryValorisation = getParameterForFetch('POST', body);
    fetch(urlValorisationConversionRate, queryValorisation)
      .then(res => res.json())
      .then(res => {
        setValorisationConversionRate(res);
        toggleRowDisplay(conversionRate._id);
      });
  }

  const [codeIsinRowDisplayed, setCodeIsinRowDisplayed] = useState();

  function toggleRowDisplay(codeIsin) {
    if (codeIsin === codeIsinRowDisplayed) {
      setCodeIsinRowDisplayed(null);
    } else {
      setCodeIsinRowDisplayed(codeIsin);
    }
  }

  useEffect(() => {
    const url = `${API_SERVEUR}${urlFetch}`;
    const parameters = getParameterForFetch('POST', propertyBody);
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setdataConversionRate(res));
  }, [propertyBody]);

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(50);
  function increment() {
    if (limit + 50 > valorisationConversionRate.length) {
      return;
    }
    const newSkip = skip + 50;
    const newLimit = limit + 50;
    setSkip(newSkip);
    setLimit(newLimit);
  }

  function decrement() {
    if (skip - 50 < 0) {
      return;
    }
    const newSkip = skip - 50;
    const newLimit = limit - 50;
    setSkip(newSkip);
    setLimit(newLimit);
  }
  return (
    <div className="tableContainer medium">
      <table>
        <thead>
          <tr>
            <td className="medium-size">{t('CODE_ISIN')}</td>
            <td className="large-size">{t('LABEL')}</td>
            <td>{t('COTATION_LOCATION')}</td>
            <td className="small-size">{t('DEVISE')}</td>
            <td className="medium-size">{t('DEVISE2')}</td>
            <td>{t('RECUPERATION_STATE')}</td>
            <td className="small-size">{t('VOIR')}</td>
          </tr>
        </thead>
        {
          <tbody>
            {dataConversionRate.map(conversionRateDetails => (
              <Fragment key={conversionRateDetails._id}>
                <tr>
                  <td>{conversionRateDetails.codeIsin}</td>
                  <td>{conversionRateDetails.label}</td>
                  <td>{conversionRateDetails.cotationLocation}</td>

                  <td>{conversionRateDetails.devise}</td>
                  <td>{conversionRateDetails.devise2}</td>

                  <td />
                  <td>
                    <button
                      className="roundButton roundSecondary ripple"
                      onClick={() =>
                        getValorisationConversionRateForConversionRate(conversionRateDetails)
                      }
                      type="button"
                    >
                      <i className="icon-eye" />
                    </button>
                  </td>
                </tr>
                {codeIsinRowDisplayed === conversionRateDetails._id && (
                  <tr key={conversionRateDetails._id}>
                    <td colSpan="9" className="subTableContainer">
                      <TableOfSharedReference
                        values={valorisationConversionRate}
                        increment={increment}
                        decrement={decrement}
                        skip={skip}
                        limit={limit}
                        deletionUrl={urlDeletionConversionRate}
                      />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        }
      </table>
    </div>
  );
}

export default ConversionPageBody;
