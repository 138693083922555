/* eslint-disable react/button-has-type */
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslate } from 'react-translate';
import getParameterForFetch from '../../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../../helpers/const/API';
import './OpcAudit.scss';

function OpcAudit(props) {
  const t = useTranslate('translation');
  const { OpcValues, history } = props;
  const createHash = () => {
    if (OpcValues) {
      return OpcValues.codeIsin;
    }
    return '';
  };

  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    if (history.location.state.opc._id) {
      return setEnabled(false);
    }
  }, [history.location.state]);
  const [auditForOpc, setAuditForOpc] = useState([]);
  const getAuditForOpc = useCallback(() => {
    const body = {
      codeIsin: OpcValues ? history.location.state.opc.codeIsin : '',
      opc: history.location.state.opc._id,
    };
    const url = `${API_SERVEUR}api/auditResult/getRecentAuditForOpc`;
    const parameters = getParameterForFetch('POST', body);
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setAuditForOpc(res));
  }, [OpcValues, history.location.state.opc._id, history.location.state.opc.codeIsin]);

  useEffect(() => {
    getAuditForOpc();
  }, [OpcValues, getAuditForOpc]);

  const getDateToDisplay = date => moment(date).format('DD-MM-YYYY');
  return (
    <div className="blockContainer">
      <div>
        <h4 className="subtitle">{t('OPC_AUDIT_TITLE')}</h4>
      </div>
      {auditForOpc.length ? (
        <Fragment>
          {auditForOpc.map(audit => (
            <div key={audit.realisationDate}>
              <fieldset>
                <legend>
                  {t('CONTROLE_BETWEEN')}:{getDateToDisplay(audit.startDate)} {t('AND_THE')}{' '}
                  {getDateToDisplay(audit.endDate)}
                </legend>

                <div className="infoButton">
                  {(audit.missingData && audit.missingData.length > 0) && (
                      <p className="incompletAudit">
                        <i className="icon-warning" />
                        {t('INCOMPLETE')}
                      </p>
                  )}
                </div>

                <div className="row infoContainer">
                  <span className="columns small-3">{t('DONE_THE')}:</span>
                  <span className="columns shrink aboutData">
                    {getDateToDisplay(audit.realisationDate)}
                  </span>
                </div>

                <div className="row infoContainer">
                  <span className="columns small-3">{t('COMPARED_VALUES')}:</span>
                  <span className="columns shrink aboutData">{audit.resultAudit ? audit.resultAudit.length : ""}</span>
                </div>

                <Link
                    to={{
                      pathname: '/resultAudit',
                      search: `?codeIsin=`,
                      hash: `${createHash()}`,
                      state: Object.assign(audit, { id: OpcValues._id }),
                    }}
                >
                  <div className="buttonRight">
                    <button type="button" className="ant-btn button secondary">
                      {' '}
                      {t('SHOW_AUDIT')}
                    </button>
                  </div>
                </Link>{' '}
              </fieldset>
            </div>
          ))}
        </Fragment>
      ) : (
        <p className="text-center">{t('NO_AUDIT_DONE_YET')}</p>
      )}
      <div className="buttonRight">
        <button className="ant-btn outlineSecondary button ripple" disabled={enabled}>
          <Link
              to={{
                pathname: '/auditOpc',

                state: history.location.state,
              }}
          />
       {t('DO_AN_AUDIT')}
        </button>
      </div>
    </div>
  );
}

export default withRouter(OpcAudit);
