/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useTranslate } from 'react-translate';
import { DatePicker, Button, Tooltip } from 'antd';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import getParameterForFetch from '../../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../../helpers/const/API';
import handleServerResponse from '../../../helpers/reponseManager/reponseHandler';
import './AuditValidation.scss';
import Months from '../../../helpers/const/Months';

function AuditValidation(props) {
  const t = useTranslate('translation');
  const { history } = props;
  const [liquidationValueForPeriod, setLiquidationValueForPeriod] = useState([]);
  const [message, setMessage] = useState('PERIOD_HAVE_NO_LIQUIDATION_VALUE');
  const [toggleLiquidationValues, setToggleLiquidationValues] = useState(false);
  const [OpcValues, setOpcValues] = useState(history.location.state.opc);
  const [realisationButtonAuditLocked, setrealisationButtonAuditLocked] = useState(Boolean);
  const [dataToRealiseAudit, setDataToRealiseAudit] = useState({});
  const [lastKnowValueOfClosingMonth, setLastKnowValueOfClosingMonth] = useState({});

  const [compositeBeforeStartDate, setCompositeBeforeStartDate] = useState(false);
  const [
    lastKnowLiquidativeValueForOpcBeforePeriod,
    setLastKnowLiquidativeValueForOpcBeforePeriod,
  ] = useState({});
  const [date, setDate] = useState({
    auditRealisation: moment(new Date(), 'DD-MM-YYYY'),
    endDate: moment(new Date(), 'DD-MM-YYYY'),
    startDate: moment(new Date(), 'DD-MM-YYYY'),
  });

  const updateDate = (field, value) => {
    setDate({
      ...date,
      [field]: value,
    });
  };

  useEffect(() => {
    if (
      Object.keys(lastKnowValueOfClosingMonth).length
      && Object.keys(lastKnowLiquidativeValueForOpcBeforePeriod).length
      && liquidationValueForPeriod.length > 1
      && !compositeBeforeStartDate.isOk
    ) {
      return setrealisationButtonAuditLocked(false);
    }
    else {
      setrealisationButtonAuditLocked(true);
      if (compositeBeforeStartDate.isOk) {
        setMessage('NO_INDICE_COMPOSITE_BEFORE_PERIOD');
      } else if (!Object.keys(lastKnowValueOfClosingMonth).length || !Object.keys(lastKnowLiquidativeValueForOpcBeforePeriod).length) {
        setMessage('NO_DATA_BEFORE_PERIOD');
      } else {
        setMessage('PERIOD_HAVE_NO_LIQUIDATION_VALUE');
      }
    }
  }, [lastKnowValueOfClosingMonth, liquidationValueForPeriod, compositeBeforeStartDate]);

  const getOpcByCodeISIN = useCallback(() => {
    const body = getParameterForFetch('POST', { _id: history.location.state.opc._id });
    const url = `${API_SERVEUR}api/opc/getOpcById`;
    fetch(url, body)
      .then(res => res.json())
      .then(res => setOpcValues(res.data));
  }, [history.location.state.opc._id]);

  const onRealisationChange = value => {
    updateDate('auditRealisation', value);
  };
  const onStartChange = value => {
    updateDate('startDate', value);
  };

  const onEndChange = value => {
    updateDate('endDate', value);
  };

  function toggleLiquidativeValues() {
    setToggleLiquidationValues(!toggleLiquidationValues);
  }

  const getAllLiquidativeValuesForPeriodByOpcCodeIsin = useCallback(() => {
    const body = {
      codeIsin: OpcValues.codeIsin,
      period: date,
      cotationLocation: OpcValues.cotationLocation,
    };
    const parameters = getParameterForFetch('POST', body);
    const url = `${API_SERVEUR}api/liquidationValue/getLiquidationValueByOpcIsinCodeForPeriod`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setLiquidationValueForPeriod(res))
      .catch(res => handleServerResponse(res));
  }, [OpcValues.codeIsin, OpcValues.cotationLocation, date]);

  const getlastKnowValueOfClosingMonth = useCallback(() => {
    const body = { codeIsin: OpcValues.codeIsin, closingMonth: OpcValues.closingMonth, period: date };
    const parameters = getParameterForFetch('POST', body);
    const url = `${API_SERVEUR}api/liquidationValue/getLastValueOfReferenceForOpc`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setLastKnowValueOfClosingMonth(res[0] || {}));
  }, [OpcValues.closingMonth, OpcValues.codeIsin, date]);

  const getLastLiquidativeValueForOpc = useCallback(() => {
    const body = { codeIsin: OpcValues.codeIsin, period: date };
    const parameters = getParameterForFetch('POST', body);
    const url = `${API_SERVEUR}api/liquidationValue/getLasttKnowLiquidativeValueOfOpc`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setLastKnowLiquidativeValueForOpcBeforePeriod(res[0] || {}));
  }, [OpcValues.codeIsin, date]);

  function saveAuditValue() {
    const body = { codeIsin: OpcValues.codeIsin, period: date };
    const url = `${API_SERVEUR}api/audit/saveAudit`;
    const parameters = getParameterForFetch('POST', body);
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => {
        if (res._id) {
          sendDataToResultAudit(res);
        }
      });
  }


  const isCompositeBeforeStartDate = useCallback(() => {
    const body = {
      codeIsin: OpcValues.codeIsin,
      period: date,
      cotationLocation: OpcValues.cotationLocation,
    };
    const parameters = getParameterForFetch('POST', body);
    const url = `${API_SERVEUR}api/composite/isCompositeBeforeStartDate`;
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setCompositeBeforeStartDate(res))
      .catch(res => handleServerResponse(res));
  }, [date]);


  function sendDataToResultAudit(auditId) {
    const periodObjectToSend = {
      startDate: new Date(date.startDate),
      endDate: new Date(date.endDate),
      realisationDate: new Date(date.auditRealisation),
    };
    setDataToRealiseAudit(
      Object.assign(dataToRealiseAudit, OpcValues, auditId, periodObjectToSend, {
        id: OpcValues._id,
      }),
    );
    history.push('/resultAudit', dataToRealiseAudit);
  }

  const getMatchingMonthOfClosing = monthNumber => {
    if (!monthNumber) {
      return '';
    }
    const month = Months.find(Month => Month.value === monthNumber);

    return month.name;
  };

  const transformToValidDate = dateToTransform => {
    return moment(new Date(dateToTransform)).format('DD-MM-YYYY');
  };

  useEffect(() => {
    getOpcByCodeISIN();
  }, [getOpcByCodeISIN]);

  useEffect(() => {
    getAllLiquidativeValuesForPeriodByOpcCodeIsin();
    getlastKnowValueOfClosingMonth();
    getLastLiquidativeValueForOpc();
    isCompositeBeforeStartDate();
  }, [date]);

  return (
    <div>
      <div>
        <h1 className="title">
          {t('AUDIT_OPC')}:
          {`${OpcValues ? OpcValues.codeIsin : ''}- ${OpcValues ? OpcValues.label : ''}`}
        </h1>

        <Link
          to={{
            pathname: '/gestionOpc',
            state: history.location.state,
            search: `?codeIsin=#${OpcValues ? OpcValues.codeIsin : ''}`,
          }}
        >
          <Button className="primary button ripple" type="primary">
            {t('ANNULER')}
          </Button>
        </Link>

        <div className="blockContainer auto">
          <h4 className="subtitle">{t('PREPARATION_AUDIT')}</h4>
          <div className="row">
            <fieldset className="columns small-12 aboutContainer">
              <legend>A propos de l&apos;audit</legend>

              <div className="row">
                <div className="columns small-14">
                  <div className="row datePickerContainer first">
                    <span className="columns small-12">{t('DATE_REALISATION_AUDIT')}</span>
                    <DatePicker
                      className="columns shrink"
                      format="DD-MM-YYYY"
                      defaultValue={date.auditRealisation}
                      value={date.auditRealisation}
                      name="auditRealisation"
                      onChange={onRealisationChange}
                      disabledTime
                      readOnly={false}
                    />
                  </div>

                  <div>
                    <div className="row datePickerContainer second">
                      <span className="columns small-12">{t('DATE_START_AUDIT')}</span>
                      <DatePicker
                        className="columns shrink"
                        format="DD-MM-YYYY"
                        defaultValue={date.startDate}
                        value={date.startDate}
                        placeholder="Start"
                        name="startDate"
                        onChange={onStartChange}
                        readOnly={false}
                      />
                    </div>

                    <div className="row datePickerContainer">
                      <span className="columns small-12">{t('DATE_END_AUDIT')}</span>
                      <DatePicker
                        className="columns shrink"
                        format="DD-MM-YYYY"
                        defaultValue={date.endDate}
                        value={date.endDate}
                        placeholder="End"
                        name="endDate"
                        onChange={onEndChange}
                        readOnly={false}
                      />
                    </div>
                  </div>
                </div>

                <div className="columns auto buttonPosition">
                  <Button
                    className="primary button ripple"
                    type="primary"
                    onClick={toggleLiquidativeValues}
                  >
                    {t('TOGGLE_LIQUIDATIVE_VALUES')}
                  </Button>
                </div>
              </div>
            </fieldset>

            <fieldset className="columns auto rightContainer">
              <legend>A propos de l&apos;OPC</legend>

              <div className="row">
                <div className="columns small-16 rightSeparator">
                  <div className="row infoContainer">
                    <span className="columns  small-14">{t('NATURE')} :</span>
                    <span className="columns shrink aboutData">
                      {OpcValues ? OpcValues.nature : '1'}
                    </span>
                  </div>

                  <div className="row infoContainer">
                    <span className="columns  small-14">{t('DEVISE')} :</span>
                    <span className="columns shrink aboutData">
                      {OpcValues ? OpcValues.devise : '1'}
                    </span>
                  </div>

                  <div className="row infoContainer">
                    <span className="columns  small-14">{t('SEUIL_ERROR')} :</span>
                    <span className="columns shrink aboutData">
                      {OpcValues ? OpcValues.maxTrackingError : '1'}
                    </span>
                  </div>

                  <div className="row infoContainer">
                    <span className="columns  small-14">{t('ANALYSE_ECART')} :</span>
                    <span className="columns shrink aboutData">
                      {OpcValues ? OpcValues.percentageDif : '1'}
                    </span>
                  </div>

                  <div className="row infoContainer">
                    <span className="columns  small-14">{t('SEUIL_CORRELATION')} :</span>
                    <span className="columns shrink aboutData">
                      {OpcValues ? OpcValues.coefCorrelation : '1'}
                    </span>
                  </div>
                </div>

                <div className="columns small-8">
                  <div className="row aboutContainer">
                    <span className="columns  small-24 text-center">{t('MONTH_CLOTURE')} :</span>
                    <span className="columns small-24 text-center aboutData">
                      {OpcValues ? getMatchingMonthOfClosing(OpcValues.closingMonth) : 'Décembre'}
                    </span>
                  </div>

                  <div className="row aboutContainer">
                    <span className="columns  small-24 text-center">{t('AUDIT_FREQUENCE')} :</span>
                    <span className="columns small-24 text-center aboutData">
                      {OpcValues ? OpcValues.frequence : '23456'}
                    </span>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div className="table-opc-audit blockContainer medium">
        {toggleLiquidationValues && liquidationValueForPeriod.length ? (
          <table>
            <thead>
            <tr>
              <th>{t('VALORISATIONDATE')}</th>
              <th>{t('LIQUIDATIVE_VALUE')}</th>
              <th>{t('COUPON')}</th>
              <th>{t('DEVISE')}</th>
            </tr>
            </thead>
            <tbody>
            {lastKnowValueOfClosingMonth ? (
              <Fragment>
                <tr key="lastKnowValueOfClosingMonth">
                  <td>
                    {moment(new Date(lastKnowValueOfClosingMonth.valorisationDate)).format(
                      'DD-MM-YYYY',
                    )}
                  </td>
                  <td>{lastKnowValueOfClosingMonth.liquidatingValue}</td>
                  <td>
                    {lastKnowValueOfClosingMonth
                      ? lastKnowLiquidativeValueForOpcBeforePeriod.couponPaid
                      : '0.000000'}
                  </td>
                  <td>{OpcValues.devise}</td>
                </tr>
              </Fragment>
            ) : (
              <Fragment/>
            )}
            {lastKnowLiquidativeValueForOpcBeforePeriod ? (
              <Fragment>
                <tr key="lastKnowLiquidativeValueForOpcBeforePeriod">
                  <td>
                    {lastKnowLiquidativeValueForOpcBeforePeriod.valorisationDate
                      ? transformToValidDate(
                        lastKnowLiquidativeValueForOpcBeforePeriod.valorisationDate,
                      )
                      : t('NO_DATA_BEFORE_PERIOD')}
                  </td>
                  <td>{lastKnowLiquidativeValueForOpcBeforePeriod.liquidatingValue}</td>
                  <td>
                    {lastKnowLiquidativeValueForOpcBeforePeriod
                      ? lastKnowLiquidativeValueForOpcBeforePeriod.couponPaid
                      : '0.0000000'}
                  </td>
                  <td>{OpcValues.devise}</td>
                </tr>
              </Fragment>
            ) : (
              <Fragment/>
            )}
            {liquidationValueForPeriod.map(liquidativeValue => (
              <tr key={liquidativeValue._id}>
                <td>
                  {transformToValidDate(liquidativeValue.valorisationDate)}
                </td>
                <td>{liquidativeValue.liquidatingValue}</td>
                <td>{liquidativeValue.couponPaid ? liquidativeValue.couponPaid : '0.0000000'}</td>
                <td>{OpcValues.devise}</td>
              </tr>
            ))}
            </tbody>
          </table>
        ) : (
          <div/>
        )}
      </div>
      <div className='buttonContainer'>
        <Tooltip title={t(message)} visible={realisationButtonAuditLocked}>
          <Button onClick={saveAuditValue} disabled={realisationButtonAuditLocked}>
            {t('START_AUDIT')}
          </Button>
        </Tooltip>
      </div>

    </div>
  );
}

export default withRouter(AuditValidation);
