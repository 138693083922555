import React from 'react';
import propTypes from 'prop-types';
import { Popconfirm } from 'antd';

const UploadFile = props => {
  const { submit, typeOfData } = props;
  return (
    <Popconfirm
      title={`Vous êtes sur le point d'importer des ${typeOfData}`}
      onConfirm={submit}
      okText="Confirmer"
      cancelText="Annuler"
    >
      <button type="submit" className="ant-btn secondary button ripple margin-right-1">
        Lancer l&apos;import
      </button>
    </Popconfirm>
  );
};

UploadFile.propTypes = {
  submit: propTypes.func.isRequired,
};

export default UploadFile;
