/* eslint-disable react/display-name */
import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-translate';
import moment from 'moment';
import getParameterForFetch from '../../helpers/function/paramFetch';
import { Popconfirm } from 'antd';

function TableOfSharedReference(props) {

  const { increment, decrement, skip, limit, deletionUrl } = props;
  const [valorisationIndices, setValorisationIndices] = useState(props.values);
  const t = useTranslate('translation');

  function deleteValorisationIndice(indiceId) {
    const url = deletionUrl + indiceId;
    const query = getParameterForFetch('DELETE');
    fetch(url, query)
      .then(res => res.json())
      .then((res) => {
        if (res.success === true) {
          const newValorisationIndices = valorisationIndices.filter((item) => item._id !== indiceId);
          setValorisationIndices(newValorisationIndices);
        }
      });
  }


  return (
    <Fragment>
      {valorisationIndices.length === 0 ? (
        <span/>
      ) : (
        <span className="paginationContainer">
          <button className="ant-btn secondary button ripple" type="button" onClick={decrement}>
            -
          </button>
          <div className="paginationContainer-text">
            {skip} - {limit}
          </div>
          <button className="ant-btn secondary button ripple" type="button" onClick={increment}>
            +
          </button>
        </span>
      )}

      <table className="subTable">
        <thead>
        <tr>
          <th>{t('VALORISATIONDATE')}</th>
          <th>{t('FIRSTSTOCKMARKET')}</th>
          <th>{t('LOWESTSTOCKMARKET')}</th>
          <th>{t('HIGHESTSTOCKMARKET')}</th>
          <th>{t('ESTIMATIONSTOCKMARKET')}</th>
          <th>{t('LASTSTOCKMARKET')}</th>
          <th></th>
        </tr>
        </thead>

        <tbody>
        {valorisationIndices.length === 0 ? (
          <tr>
            <td colSpan="7">{t('NO_VALUES_TO_DISPLAY')}</td>
          </tr>
        ) : (
          valorisationIndices.slice(skip, limit).map(value => (
            <tr key={value.valorisationDate}>
              <td>{moment(value.valorisationDate).format('DD-MM-YYYY')}</td>
              <td>{value.firstStockMarket}</td>
              <td>{value.lowestStockMarket}</td>
              <td>{value.highestStockMarket}</td>
              <td>{value.estimationStockMarket}</td>
              <td>{value.lastStockMarket}</td>
              <td>
                <Popconfirm
                  title={t('DELETION_WARNING')}
                  onConfirm={() => deleteValorisationIndice(value._id)}
                  okText="Confirmer"
                  cancelText="Annuler"
                >
                  <button
                    className="roundButton roundSecondary"
                    type="button"
                  >
                    <i className="icon-bin"/>
                  </button>
                </Popconfirm>
              </td>
            </tr>
          ))
        )}
        </tbody>
      </table>
    </Fragment>
  );
}

export default TableOfSharedReference;
