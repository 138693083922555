/* eslint-disable react/jsx-key */
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslate } from 'react-translate';
import getParameterForFetch from '../../../helpers/function/paramFetch';
import { API_SERVEUR } from '../../../helpers/const/API';
import TableOfSharedReference from '../../../component/shared/TableOfSharedReference';
import { Link } from 'react-router-dom';

function ReferentielPageBody(props) {
  const { propertyBody, period } = props;
  const [valorisationIndice, setValorisationIndice] = useState([]);
  const [codeIsinRowDisplayed, setCodeIsinRowDisplayed] = useState();
  const t = useTranslate('translation');
  const urlFetch = 'api/indice/getIndiceMatching ';
  const urlDeletionIndice = `${API_SERVEUR}api/valorisationIndice/deleteValorisationIndice/`;


  const [indices, setIndices] = useState([]);

  const [pagination, setPagination] = useState(1);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(50);

  function increment() {
    const newPagination = pagination + 1;
    const newSkip = skip + 50;
    const newLimit = limit + 50;
    setSkip(newSkip);
    setLimit(newLimit);
    setPagination(newPagination);
  }

  function decrement() {
    const newPagination = pagination - 1;
    const newSkip = skip - 50;
    const newLimit = limit - 50;
    setSkip(newSkip);
    setLimit(newLimit);
    setPagination(newPagination);
  }

  function getValorisationIndicesByCodeIsin(indice) {
    setValorisationIndice([]);
    const urlValorisationConversionRate = `${API_SERVEUR}api/valorisationIndice/getValorisationIndiceByLabelAndCodeIsin`;
    const body = {
      period,
      codeIsin: indice.codeIsin,
      skip,
      limit,
      cotationLocation: indice.cotationLocation,
    };
    const queryValorisation = getParameterForFetch('POST', body);
    fetch(urlValorisationConversionRate, queryValorisation)
      .then(res => res.json())
      .then(res => {
        setValorisationIndice(res);
        toggleRowDisplay(indice._id);
      });
  }

  function isSourceInterne(source) {
    return source ? 'INTERNE' : 'EXTERNE';
  }

  function toggleRowDisplay(id) {
    if (id === codeIsinRowDisplayed) {
      setCodeIsinRowDisplayed(null);
    } else {
      setCodeIsinRowDisplayed(id);
    }
  }

  useEffect(() => {
    const url = `${API_SERVEUR}${urlFetch}`;
    const parameters = getParameterForFetch('POST', propertyBody);
    fetch(url, parameters)
      .then(res => res.json())
      .then(res => setIndices(res));
  }, [propertyBody]);

  return (
    <div className="tableContainer medium">
      <table>
        <thead>
        <tr>
          <td className="medium-size">{t('CODE_ISIN')}</td>
          <td className="large-size">{t('LABEL')}</td>
          <td>{t('SOURCE')}</td>
          <td>{t('COTATION_LOCATION')}</td>
          <td className="small-size">{t('DEVISE')}</td>
          <td>{t('OPC_USING')}</td>
          <td>{t('RECUPERATION_STATE')}</td>
          <td className="small-size">{t('VOIR')}</td>
          <td className="small-size">{t('EDIT')}</td>
        </tr>
        </thead>
        <tbody>
        {indices.map(indice => (
          <Fragment key={indice._id}>
            <tr>
              <td>{indice.codeIsin}</td>
              <td>{indice.label}</td>
              <td>{t(isSourceInterne(indice.source))}</td>
              <td>{indice.cotationLocation}</td>
              <td>{indice.devise}</td>

              <td>{indice.numberOfOpcUsingIndice}</td>
              <td/>
              <td>
                <button
                  className="roundButton roundSecondary"
                  type="button"
                  onClick={() => getValorisationIndicesByCodeIsin(indice)}
                >
                  <i className="icon-eye"/>
                </button>
              </td>
              <td>
                <Link
                  to={{
                    pathname: '/newIndice',
                    state: { indice },
                  }}
                >
                  <button
                    className="roundButton roundSecondary"
                    type="button"
                  >
                    <i className="icon-pencil"/>
                  </button>
                </Link>
              </td>
            </tr>
            {codeIsinRowDisplayed === indice._id && (
              <tr>
                <td colSpan="9" className="subTableContainer">
                  <TableOfSharedReference
                    values={valorisationIndice}
                    increment={increment}
                    decrement={decrement}
                    skip={skip}
                    limit={limit}
                    deletionUrl = {urlDeletionIndice}
                  />
                </td>
              </tr>
            )}
          </Fragment>
        ))}
        </tbody>
      </table>
    </div>
  );
}

export default ReferentielPageBody;
