import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SideNav.scss';
import NavButton from './NavButton/NavButton';

class SideNavPage extends Component {
  constructor(props) {
    super(props);
    const { navigation } = this.props;
    this.state = {
      showNav: false,
      navigation,
    };
  }

  openNavClick = e => {
    e.preventDefault();
    this.openNav();
  };

  closeNavClick = e => {
    e.preventDefault();
    this.closeNav();
  };

  openNav = () => {
    this.setState({
      showNav: true,
    });

    document.addEventListener('keydown', this.handleEscKey);
  };

  closeNav = () => {
    this.setState({
      showNav: false,
    });
    document.removeEventListener('keydown', this.handleEscKey);
  };

  handleEscKey = e => {
    if (e.key === 'Escape') {
      this.closeNav();
    }
  };

  render() {
    const { showNav, navigation } = this.state;
    const { userIsAdmin } = this.props;
    const navCoverStyle = { width: showNav ? '100%' : '0' };
    const sideNavStyle = { width: showNav ? '250px' : '0' };

    return (
      <React.Fragment>
        <span onClick={this.openNavClick} role="presentation" className="open-nav hide-for-print">
          &#9776;
        </span>
        <div
          onClick={this.navCoverClick}
          className="nav-cover"
          role="presentation"
          style={navCoverStyle}
        />
        <div name="side-nav" className="side-nav" role="presentation" style={sideNavStyle}>
          <button href="#" onClick={this.closeNavClick} className="close-nav" type="button">
            &times;
          </button>
          {navigation.map(nav => (
            <NavButton path={nav.path} value={nav.value} key={nav.id} icon={nav.icon} />
          ))}
          {userIsAdmin ? <NavButton path="/admin/gestion" value="Administrateur" icon="icon-cog" /> : ''}
        </div>
      </React.Fragment>
    );
  }
}

SideNavPage.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default SideNavPage;
