import React from 'react';
import PropTypes from 'prop-types';

const NavButton = props => {
  const { path, value, icon } = props;
  return (
    <div>
      <a href={path}>
        <i className={icon} />
        <span>{value}</span>
      </a>
    </div>
  );
};
NavButton.propTypes = {
  path: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
export default NavButton;
